import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Navbar from './component/navbar/navbar'
import Inicio from './pages/inicio/inicio'
import {dataInicio} from './data/inicio'
import Footer from './component/footer/footer'
import AtencionAlCliente from './pages/convenios/AtencionAlCliente'
import BuscandoDepa from './pages/BuscandoDepa/BuscandoDepa'
import AtencionPropietario from './pages/AtencioPropietario/AtencioPropietario'
import Conocenos from './pages/conocenos/conocenos'
import { dataConocenos } from './data/conocenos'
import Contactanos from './pages/cotactanos/contactanos'
import ModalPoliticas from './component/modalPoliticas/modalPoliticas'
import Conveniosybeneficios from './pages/conveniosybeneficios/conveniosybeneficios'
import Electrodomesticos from './pages/electrodomesticos/electrodomesticos'
import Inversion from './pages/inversion/inversion'
import Loader from './component/loader/loader'
import Referidos from './pages/Referidos/referidos'
import Multiproducto from './pages/Multiproducto/multiproducto'
import { Whatsapp } from './component/whatsapp/whatsapp'
// import Page from './component/range/page'
export default class Routes extends Component {
  componentDidMount(){
    function esDispositivoiOS(){
        var userAgent = window.navigator.userAgent;
        console.log(userAgent)
        let typeDevice = (/iPhone|iPod|iPad|Mac/.test(userAgent));
        if(typeDevice){
            document.querySelector("#root").classList.add("IOS")
        }
    }
    esDispositivoiOS()
  }  
  render() {
    return (
        <Router>
            {
                process.env.NODE_ENV == 'development' ? null : <Loader></Loader> 
            }
            <Navbar></Navbar>
            <Whatsapp></Whatsapp>
            {
                process.env.NODE_ENV == 'development' ? null : <ModalPoliticas></ModalPoliticas> 
            }
            <Switch>
                <Route exact path="/">
                    <Inicio data={dataInicio}></Inicio>
                </Route>
                <Route exact path="/atencion-al-cliente">
                    {/* <AtencionAlCliente></AtencionAlCliente> */}
                    <AtencionPropietario></AtencionPropietario>
                </Route>
                <Route exact path="/preguntas-frecuentes">
                    <BuscandoDepa></BuscandoDepa>
                </Route>
                {/* <Route exact path="/atencion-al-propietario">
                </Route> */}
                <Route exact path="/conocenos">
                    <Conocenos data={dataConocenos}></Conocenos>
                </Route>
                <Route exact path="/referidos">
                    <Referidos></Referidos>
                </Route>
                <Route exact path="/contactanos">
                    <Contactanos></Contactanos>
                </Route>
                {/* <Route exact path="/convenios-y-beneficios">
                    <Conveniosybeneficios></Conveniosybeneficios>
                </Route> */}
                {/* <Route exact path="/aliados">
                    <Electrodomesticos></Electrodomesticos>
                </Route> */}
                <Route exact path="/inversion">
                    <Inversion></Inversion>
                </Route>
                <Route exact path="/multiproducto">
                    <Multiproducto/>
                </Route>
            </Switch>
            <Footer></Footer>
        </Router>
    )
  }
}

