const host = "https://cosapiinmobiliaria.com.pe"

export const OwnerEmail = ({fname, project}) =>`<table cellspacing="0" cellpadding="0" border="0" style="color:#333;background:#fff;padding:0;margin:0;width:100%;font:15px/1.25em 'Helvetica Neue',Arial,Helvetica">
<tbody>
<tr width="100%">
    <td valign="top" align="left" style="background:#eef0f1;font:15px/1.25em 'Helvetica Neue',Arial,Helvetica">
        <table style="border:none;padding:0 18px;margin:50px auto;width:500px">
            <tbody>
            <tr width="100%" height="60">
                <td valign="top" align="left" style="border-top-left-radius:4px;border-top-right-radius:4px;background:#fff bottom left repeat-x;padding:10px 18px;text-align:center">
                    <img src="${host}/resources/logo.png" title="Cosapi Inmobiliaria">
                </td>
            </tr>
            <tr width="100%">
                <td valign="top" align="left" style="background:#fff;padding:18px">
                    <h1 style="font-size:20px;margin:16px 0;color:#333;text-align:center">Hola ${ fname } a continuación encontrarás los manuales del proyecto en donde resides</h1>
                    <p style="font:15px/1.25em 'Helvetica Neue',Arial,Helvetica;color:#333;text-align:center">
                      <a href="${ host }/resources/manuals/${ project }-manual-de-convivencia.pdf" style="float: left; width: 50%;">
                        <img src="${ host }/resources/mails/botonConvivencia.png" style="width: 100%;"/>
                    </a>
                      <a href="${ host }/resources/manuals/${ project }-manual-de-uso-y-mantenimiento.pdf" style="float: left; width: 50%;">
                        <img src="${ host }/resources/mails/botonMantenimiento.png" style="width: 100%;"/>
                    </a>
                    </p>
                </td>
            </tr>
            <tr width="100%" height="60">
                <td valign="top" style="background:#fff;padding:18px; text-align: center;">
                    <p>¡Esperamos que esta información te haya sido útil!</p>
                </td>
            </tr>
            </tbody>
        </table>
    </td>
</tr>
</tbody>
</table>`