import { useState } from "react";
import { checkableBoolProps, FormContainer, setInputProps } from "../../component/common/forms/Form";
import { MultiplyInterestedSchema, ReferecialSchema } from '../../component/common/forms/constraints/ValidatonSchemas'
import MediaQuery from 'react-responsive';
import { PROJECTS_DATA, PROJECTS_TIPOLOGIES } from '../../data/projects';
import OwlCarousel from "react-owl-carousel2"
import { onChangeInput, options } from "./script";
import { dataInicio } from "../../data/inicio";
import $ from 'jquery';

const events = {
    onDragged: function(event) {},
    onChanged: function(event) {},
}
export const FormMultiproducto = () => {
    const [activeModal, setActiveModal] = useState(false)
    const [sellerPhone, setsellerPhone] = useState(false)
    const [flat_types, setTipologies] = useState([
        {name:"X01",img:require("../../assets/images/multiproducto/flat_types/tipologia-example.png"),active:false},
        {name:"X01",img:require("../../assets/images/multiproducto/flat_types/tipologia-example.png"),active:false},
        {name:"X01",img:require("../../assets/images/multiproducto/flat_types/tipologia-example.png"),active:false},
    ])
    const [initialValues,setInitialValues] = useState(
        {
            project: "",
            fase: "",
            dorms: "",
            flat_type:"",
            fname:"",
            lname:"",
            telephone:"",
            email:"",
            motive:"",
            terms:null,
    })
    const submitForm = (values) => {
        console.log(values)
        setActiveModal(true)
    }
    const closeModal = (e) => {
        if(e.target.className.includes('type-popup')) setActiveModal(false)
    }
    const changeSlider = (e) => {
        $(`.owl-carousel`).removeClass("active")
        $(`#carousel-${e.target.value || 'ativa'}`).addClass("active")
        setInitialValues(prevState => ({
            ...prevState,
            ['flat_type']: "",['fase']: "",
        }));
        document.getElementsByName("flat_type")[0].value = ""
        document.getElementsByName("fase")[0].value = ""
    }
    const changeIndexSlider = (e) => {
        let index = PROJECTS_TIPOLOGIES[initialValues.project].flat_types.findIndex((item) => item.name == e.target.value)
        let time = setInterval(() => {
            let owl = $(`#carousel-${initialValues.project || 'ativa'}`).owlCarousel()
            owl.trigger('to.owl.carousel', [index, 300]);
            clearInterval(time)
        }, 200);
    }
    return(
        <section className='information container'>
            <div className='formulario'>
                <FormContainer
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={MultiplyInterestedSchema}
                    onSubmit={submitForm}
                >
                    {form => {const {handleSubmit, isSubmitting} = form;
                    return(
                    <form className="form" onSubmit={handleSubmit}>
                        <div className={`type-popup popup ${activeModal ? "active" : ""}`} onClick={(e)=>{closeModal(e)}}>
                            <div className="content-popup">
                                <div className="btn-close type-popup" onClick={(e)=>{closeModal(e)}}><img src={require("../../assets/images/icons/btn-close.svg").default}></img></div>
                                <img className="background desktop" src={require("../../assets/images/icons/background-modal.png")}></img>
                                <img className="background movil" src={require("../../assets/images/icons/background-modal-movil.png")}></img>
                                <img className="icon-check" src={require("../../assets/images/icons/check_icon.svg").default}></img>
                                <span className="title">¡Gracias!</span>
                                <span className="sub-title ">Dentro de poco un asesor se pondrá en contacto contigo.</span>
                                {
                                    sellerPhone && 
                                    <>
                                        <span className="sub-title bold">También puedes contactarlo aquí</span>
                                        <div className="contact">
                                            <a href={`https://wa.me/51${this. n.sellerPhone}`} target="_blank"><img className="icon icon-whatsapp" src={require("../../assets/images/icons/whatsapp-white.svg").default}></img></a>
                                            <a href={`tel:${this.state.sellerPhone}`} target="_blank"><img className="icon" src={require("../../assets/images/icons/call-white.svg").default}></img></a>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className='personal'>
                            <h2 className='title text-center'>Selecciona el departamento de tu interés</h2>
                            <div className="content-inputs  mb-1">
                                <select {...setInputProps("project", "middle", form)} onChange={(e)=>{onChangeInput(setInitialValues,e);changeSlider(e)}}>
                                    <option value="">Proyecto*</option>
                                    {Object.keys(PROJECTS_DATA).map((key, i)=>
                                        PROJECTS_DATA[key].allowLeadCreation && <option key={i} value={key}>{PROJECTS_DATA[key].name}</option>
                                    )}
                                </select>
                                <select {...setInputProps("fase", "middle", form)} onChange={(e)=>{onChangeInput(setInitialValues,e)}}>
                                    <option value="">Fase*</option>
                                    {
                                        initialValues.project ?
                                        dataInicio.cardsFilter.filter((item) => item.project.toLowerCase() == initialValues.project)[0].allFases.map((fase,index)=>{
                                            return(
                                                <option key={index+"fase"} value={fase.type}>{fase.type}</option>
                                            )
                                        }) : null
                                    }
                                    {/* {
                                        initialValues.project ? 
                                        dataInicio.cardsFilter[initialValues.project].allFases.map((item,i)=>{
                                            return <option key={i+"fases"} value={item.type}>{item.type}</option>
                                        }) : null
                                    } */}
                                    {/* <option value="En lanzamiento">En lanzamiento</option>
                                    <option value="En preventa">En preventa</option>
                                    <option value="En construcción">En construcción</option>
                                    <option value="Entrega inmediata">Entrega inmediata</option> */}
                                </select>
                                <select {...setInputProps("dorms", "middle", form)} onChange={(e)=>{onChangeInput(setInitialValues,e)}}>
                                    <option value="">N° de dormitorios*</option>
                                    <option value="1">1 Dormitorio</option>
                                    <option value="2">2 Dormitorios</option>
                                    <option value="3">3 Dormitorios</option>
                                </select>
                                <select {...setInputProps("flat_type", "middle", form)} onChange={(e)=>{onChangeInput(setInitialValues,e);changeIndexSlider(e)}}>
                                    <option value="">Tipología*</option>
                                    {
                                        initialValues.project ? 
                                        PROJECTS_TIPOLOGIES[initialValues.project.toLowerCase()].flat_types.map((tipology, i)=>{
                                            return(
                                                <option value={tipology.name}>{tipology.name}</option>
                                            )
                                        })
                                        : null
                                    }
                                </select>
                            </div>
                            <MediaQuery maxWidth={500}>
                                <div className='referencial'>
                                {
                                    dataInicio.cardsFilter.map((project, i)=>{
                                        if(project.fase != "Vendido"){
                                            return(
                                                <SliderForm project={project}></SliderForm>
                                            )
                                        }
                                    })
                                }
                                </div>
                            </MediaQuery>
                            <h2 className='title text-center'>Déjanos tus datos y un asesor te contactará pronto</h2>
                            <div className="content-inputs">
                                <div className='content-input middle'>
                                    <input placeholder="Nombre(*)" {...setInputProps("fname", "", form)}></input>
                                </div>
                                <div className='content-input middle-2'>
                                    <input placeholder="Apellidos(*)" {...setInputProps("lname", "", form)}></input>
                                </div>
                                <div className='content-input middle'>
                                    <input placeholder="Teléfono (*)" {...setInputProps("telephone", "", form)}></input>
                                </div>
                                <div className='content-input middle-2'>
                                    <input placeholder="Correo Electrónico (*)" {...setInputProps("email", "", form)}></input>
                                </div>
                                <select {...setInputProps("motive", "", form)}>
                                    <option value="">Motivo de compra</option>
                                    <option value="Primera Vivienda">Primera Vivienda</option>
                                    <option value="Inversión">Inversión</option>
                                </select>
                            </div>
                            <div className="politicas">
                                <input id="terms" type="checkbox" {...checkableBoolProps("terms", "", form)}></input>
                                <label htmlFor="terms" className="label-accept"><div className="check"></div></label>
                                <span className="terms">Acepto las <a target="_blank" href={require("../../assets/files/Politica-de-privacidad.pdf")}>políticas de privacidad</a></span>
                            </div>
                            <button type="submit" className="btn-submit btn-radio c-white b-primary" disabled={isSubmitting}>{isSubmitting ? 'Enviando...' : 'Enviar'}</button>
                            
                        </div>
                        <MediaQuery minWidth={500}>
                            <div className='referencial'>
                                {
                                    dataInicio.cardsFilter.map((project, i)=>{
                                        if(project.fase != "Vendido"){
                                            return(
                                                <SliderForm project={project}></SliderForm>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </MediaQuery>
                    </form>
                    )}}
                </FormContainer>
            </div>
        </section>
    )
}

function SliderForm({project}){
    return(
        <OwlCarousel options={options} events={events} 
            className={`owl-carousel ${project.project == "Ativa" ? "active" : ""}`}
            id={`carousel-${project.project.toLocaleLowerCase()}`}>
            {
                project.flat_types.map((item, index) => {
                    return(
                        <div className="tipology"  key={index+"-tipology"}>
                            <img className='img' src={item.img} ></img>
                        </div>
                    )
                })
            }
        </OwlCarousel>
    )
}