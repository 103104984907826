import React, { Component } from 'react'
import Slider from "react-slick";

export default class SliderOptions extends Component {
    constructor(props){
        super(props)
        this.update = this.update.bind(this)
        this.convert = this.convert.bind(this)
        this.state = {
            slides:[],
            changeID:0,
            settingsCards:{
                dots: false,
                arrows:false,
                infinite: false,
                centerMode: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true,
            },
        }
    }
    componentDidMount(){
        // this.setState({slides:[{title:"Lince"},{title:"2 dorms"},],})
        this.setState({slides:[{title:"1 dorms"},{title:"2 dorms"},{title:"3 dorms"}],})
    }
    update(option){
        const { slides } = this.state;
        let newSlides = slides.filter((item)=>{
            if(item.title != option){
                return item
            }
        })
        console.log(newSlides)
        this.setState({
            slides:newSlides,
        });
    }
    convert = (value) => {
        if(value == 0){
            return "Lanzamiento"
        }
        if(value == 1){
            return "Preventa"
        }
        if(value == 2){
            return "Construcción"
        }
        if(value == 3){
            return "Entrega inmediata"
        }
    }
    render() {
        return (
            <div className="content-btn-filter-buttons" >
                <Slider {...this.state.settingsCards} key={this.state.changeID}>
                    {
                        this.props.options.distric == "all" ? null : 
                        <div className="option">
                            <span className="text-option">{this.props.options.distric}</span>
                            <div className="btn-close">
                                <div className="close" onClick={(e)=>{this.props.options.updateDistric(this.props.options.distric)}}></div>
                            </div>                       
                        </div>
                    }
                    {
                        this.props.options.numDorms.length == 0 ? null :
                        this.props.options.numDorms.map((dorm)=>{
                            return(
                            <div className="option">
                                <span className="text-option">{dorm}{dorm == 1 ? " dorm" : " dorms"}</span>
                                <div className="btn-close">
                                    <div className="close" onClick={(e)=>{this.props.options.changeDorms(e,dorm)}}></div>
                                </div>                       
                            </div>
                            )
                        })
                    }
                    {
                        this.props.options.projects.length == 0 ? null :
                        this.props.options.projects.map((project)=>{
                            return(
                            <div className="option">
                                <span className="text-option">{this.convert(project)}</span>
                                <div className="btn-close">
                                    <div className="close" onClick={(e)=>{this.props.options.updateFases(project)}}></div>
                                </div>                       
                            </div>
                            )
                        })
                    }
                </Slider>
            </div>
        )
    }
}
