export const PROJECTS = {
    midgo: "midgo",
    momen: "momen",
    ativa: "ativa",
    épiqe: "épiqe",
    muvin: "muvin",
    nesta: "nesta",
    sente: "sente",
    duplo: "duplo",
    velia: "velia",
    prana: "prana",
}

export const SPERANT_ENV = {
    cosapi: "cosapi",
    cosapilar: "cosapilar"
}

export const CRM_MEDIUMS = {
    [SPERANT_ENV.cosapi]: {
        source_ids: {
            web: 45, // cosapi_página web
            colaborator: 11,
            referidos: 49
        },
        input_channels: {
            web: 4, // contacto web
        }
    },
    [SPERANT_ENV.cosapilar]: {
        source_ids: {
            web: 47, // web cosapi inmobiliaria
            colaborator: 11,
            referidos: 16
        },
        input_channels: {
            web: 10, // web cosapi inmobiliaria
        }
    },
}

export const PROJECTS_CRM = {
    [PROJECTS.midgo]: {id: 27, allowLeadCreation: true, environment: SPERANT_ENV.cosapi, template_id: null, ...CRM_MEDIUMS.cosapi},
    [PROJECTS.épiqe]: {id: 12, allowLeadCreation: true, environment: SPERANT_ENV.cosapi, template_id: null, ...CRM_MEDIUMS.cosapi},
    [PROJECTS.muvin]: {id: 13, allowLeadCreation: true, environment: SPERANT_ENV.cosapi, template_id: 58, ...CRM_MEDIUMS.cosapi},
    [PROJECTS.sente]: {id: 10, allowLeadCreation: true, environment: SPERANT_ENV.cosapilar, template_id: 73, ...CRM_MEDIUMS.cosapilar},
    [PROJECTS.nesta]: {id: 8, allowLeadCreation: true, environment: SPERANT_ENV.cosapilar, template_id: 74, ...CRM_MEDIUMS.cosapilar},
    [PROJECTS.ativa]: {id: 17, allowLeadCreation: true, environment: SPERANT_ENV.cosapi, template_id: 58, ...CRM_MEDIUMS.cosapi},
    [PROJECTS.momen]: {id: 25, allowLeadCreation: true, environment: SPERANT_ENV.cosapi, template_id: 58, ...CRM_MEDIUMS.cosapi},
    [PROJECTS.prana]: {id: null, allowLeadCreation: false, environment: SPERANT_ENV.cosapi, template_id: null, ...CRM_MEDIUMS.cosapi},
    [PROJECTS.velia]: {id: null, allowLeadCreation: false, environment: SPERANT_ENV.cosapi, template_id: null, ...CRM_MEDIUMS.cosapi},
    [PROJECTS.duplo]: {id: null, allowLeadCreation: false, environment: SPERANT_ENV.cosapilar, template_id: null, ...CRM_MEDIUMS.cosapilar},
}

const ucFirst = string => string.charAt(0).toUpperCase() + string.slice(1)

export const PROJECT_LOC = {
    [PROJECTS.midgo]: {department: "Lima", province: "Lima", district: "Lince", address: "Av. Arequipa <strong>1890</strong>", latitud: -12.0855536, longitud: -76.9733498},
    [PROJECTS.momen]: {department: "Lima", province: "Lima", district: "Breña", address: "Av. Aguarico <strong>1251</strong>", latitud: -12.0855536, longitud: -76.9733498},
    [PROJECTS.ativa]: {department: "Lima", province: "Lima", district: "Surco", address: "Av. El Derby <strong>160</strong>", latitud: -12.0855536, longitud: -76.9733498},
    [PROJECTS.épiqe]: {department: "Lima", province: "Lima", district: "Surco", address: "Av. Manuel Olguín <strong>600</strong>", latitud: -12.0855536, longitud: -76.9733498},
    [PROJECTS.muvin]: {department: "Lima", province: "Lima", district: "Lince", address: "Av. Prolongación iquitos <strong>1870</strong>", latitud: -12.080832798297171, longitud: -77.02868410158379},
    [PROJECTS.sente]: {department: "Lima", province: "Lima", district: "Breña", address: "Jr. Centenario <strong>175</strong>", latitud: -11.49638185, longitud: -77.2098442965157},
    [PROJECTS.nesta]: {department: "Lima", province: "Lima", district: "Jesús María", address: "Av. Salaverry <strong>475</strong>", latitud: -12.08694, longitud: -77.050018},
    [PROJECTS.duplo]: {department: "Lima", province: "Lima", district: "Breña", address: "Av. Brasil <strong>840</strong>", latitud: -12.067735650505, longitud: -77.0474958343434},
    [PROJECTS.prana]: {department: "Lima", province: "Lima", district: "Pueblo Libre", address: "Av. Sucre <strong>132</strong>", latitud: -12.0967589, longitud: -77.0724546},
    [PROJECTS.velia]: {department: "Lima", province: "Lima", district: "Lince", address: "Av. Belisario <strong>1035</strong>", latitud: -12.0832011, longitud: -77.0454049},
}

export const PROJECT_SELLERS = {
    [PROJECTS.épiqe]: [],
    [PROJECTS.muvin]: [],
    [PROJECTS.nesta]: [],
    [PROJECTS.sente]: [],
}
export const PROJECTS_TIPOLOGIES = {
    [PROJECTS.midgo]:{flat_types:[],},
    [PROJECTS.momen]:{flat_types:[],},
    [PROJECTS.ativa]:{flat_types:[
        {name:"208",img:require("../assets/images/multiproducto/flat_types/ativa/208.png")},
        {name:"X01",img:require("../assets/images/multiproducto/flat_types/ativa/x01.jpg")},
        {name:"X02",img:require("../assets/images/multiproducto/flat_types/ativa/x02.jpg")},
        {name:"X03",img:require("../assets/images/multiproducto/flat_types/ativa/x03.jpg")},
        {name:"X04",img:require("../assets/images/multiproducto/flat_types/ativa/x04.jpg")},
        {name:"X05",img:require("../assets/images/multiproducto/flat_types/ativa/x05.jpg")},
        {name:"X06",img:require("../assets/images/multiproducto/flat_types/ativa/x06.jpg")},
        {name:"X07",img:require("../assets/images/multiproducto/flat_types/ativa/x07.jpg")},
        {name:"X08",img:require("../assets/images/multiproducto/flat_types/ativa/x08.jpg")},
        {name:"X09",img:require("../assets/images/multiproducto/flat_types/ativa/x09.jpg")},
    ]},
    [PROJECTS.épiqe]:{flat_types:[
        {name:"X01",img:require("../assets/images/multiproducto/flat_types/épiqe/F1-X01.jpg")},
        {name:"X02",img:require("../assets/images/multiproducto/flat_types/épiqe/F1-X02.jpg")},
        {name:"X03",img:require("../assets/images/multiproducto/flat_types/épiqe/F1-X03.jpg")},
        {name:"X04",img:require("../assets/images/multiproducto/flat_types/épiqe/F1-X04.jpg")},
        {name:"X05",img:require("../assets/images/multiproducto/flat_types/épiqe/F2-X05.png")},
        {name:"X06",img:require("../assets/images/multiproducto/flat_types/épiqe/F2-X06.png")},
        {name:"X07",img:require("../assets/images/multiproducto/flat_types/épiqe/F2-X07.png")},
        {name:"X08",img:require("../assets/images/multiproducto/flat_types/épiqe/F3-X08.png")},
        {name:"X09",img:require("../assets/images/multiproducto/flat_types/épiqe/F3-X09.png")},
        {name:"X010",img:require("../assets/images/multiproducto/flat_types/épiqe/F3-X10.png")},
    ]},
    [PROJECTS.muvin]:{flat_types:[
        {name:"X01",img:require("../assets/images/multiproducto/flat_types/muvin/X01.png")},
        {name:"X02",img:require("../assets/images/multiproducto/flat_types/muvin/X02.png")},
        {name:"X03",img:require("../assets/images/multiproducto/flat_types/muvin/X03.png")},
        {name:"X04",img:require("../assets/images/multiproducto/flat_types/muvin/X04.png")},
        {name:"X05",img:require("../assets/images/multiproducto/flat_types/muvin/X05.png")},
        {name:"X06",img:require("../assets/images/multiproducto/flat_types/muvin/X06.png")},
        {name:"X07",img:require("../assets/images/multiproducto/flat_types/muvin/X07.png")},
        {name:"X08",img:require("../assets/images/multiproducto/flat_types/muvin/X08.png")},
        {name:"X09",img:require("../assets/images/multiproducto/flat_types/muvin/X09.png")},
    ]},
    [PROJECTS.sente]:{flat_types:[
        {name:"X01",img:require("../assets/images/multiproducto/flat_types/sente/F1-X01.png")},
        {name:"X02",img:require("../assets/images/multiproducto/flat_types/sente/F1-X02.png")},
        {name:"X03",img:require("../assets/images/multiproducto/flat_types/sente/F1-X03.png")},
        {name:"X04",img:require("../assets/images/multiproducto/flat_types/sente/F1-X04.png")},
        {name:"X05",img:require("../assets/images/multiproducto/flat_types/sente/F1-X05.png")},
        {name:"X06",img:require("../assets/images/multiproducto/flat_types/sente/F1-X06.png")},
        {name:"X07",img:require("../assets/images/multiproducto/flat_types/sente/F1-X07.png")},
        {name:"AX01",img:require("../assets/images/multiproducto/flat_types/sente/F2-AX01.png")},
        {name:"AX02",img:require("../assets/images/multiproducto/flat_types/sente/F2-AX02.png")},
        {name:"AX03",img:require("../assets/images/multiproducto/flat_types/sente/F2-AX03.png")},
        {name:"AX04",img:require("../assets/images/multiproducto/flat_types/sente/F2-AX04.png")},
        {name:"AX05",img:require("../assets/images/multiproducto/flat_types/sente/F2-AX05.png")},
        {name:"AX06",img:require("../assets/images/multiproducto/flat_types/sente/F2-AX06.png")},
        {name:"AX07",img:require("../assets/images/multiproducto/flat_types/sente/F2-AX07.png")},
    ]},
    [PROJECTS.nesta]:{flat_types:[
        {name:"C-X01",img:require("../assets/images/multiproducto/flat_types/nesta/C-X01-Nesta.png")},
        {name:"C-X02",img:require("../assets/images/multiproducto/flat_types/nesta/C-X02-Nesta.png")},
        {name:"C-X03",img:require("../assets/images/multiproducto/flat_types/nesta/C-X03-Nesta.png")},
        {name:"C-X04",img:require("../assets/images/multiproducto/flat_types/nesta/C-X04-Nesta.png")},
        {name:"C-X05",img:require("../assets/images/multiproducto/flat_types/nesta/C-X05-Nesta.png")},
        {name:"C-X06",img:require("../assets/images/multiproducto/flat_types/nesta/C-X06-Nesta.png")},
        {name:"X804",img:require("../assets/images/multiproducto/flat_types/nesta/NESTA_Dpto_C_804_page-0001.jpg")},
        {name:"X1002",img:require("../assets/images/multiproducto/flat_types/nesta/NESTA_Dpto_C_1002_page-0001.jpg")},
        {name:"X02",img:require("../assets/images/multiproducto/flat_types/nesta/NESTA_Dpto_C_002_page-0001.jpg")},

        {name:"BX10",img:require("../assets/images/multiproducto/flat_types/nesta/F3_X10.jpg")},
        {name:"BX11",img:require("../assets/images/multiproducto/flat_types/nesta/F3_X11.jpg")},
        {name:"BX12",img:require("../assets/images/multiproducto/flat_types/nesta/F3_X12.jpg")},
        {name:"BX13",img:require("../assets/images/multiproducto/flat_types/nesta/F3_X13.jpg")},
        {name:"BX14",img:require("../assets/images/multiproducto/flat_types/nesta/F3_X14.jpg")},

        {name:"C2 X07",img:require("../assets/images/multiproducto/flat_types/nesta/X7_NESTA.png")},
        {name:"C2 X08",img:require("../assets/images/multiproducto/flat_types/nesta/X8_NESTA.png")},
        {name:"C2 X09",img:require("../assets/images/multiproducto/flat_types/nesta/X9_NESTA.png")},
    ]},
}

export const PROJECT_LIFECYCLES = new Map([
    [PROJECTS.midgo, [{name: "", stage: "Lanzamiento"}]],
    [PROJECTS.momen, [{name: "", stage: "Preventa"}]],
    [PROJECTS.ativa, [{name: "Fase 1", stage: "Preventa"}, {name: "Fase 2", stage: "En Preventa"}]],
    [PROJECTS.épiqe, [{name: "", stage: "Entrega Inmediata"}]],
    [PROJECTS.muvin, [{name: "", stage: "Entrega Inmediata"}]],
    [PROJECTS.nesta, [{name: "Fase 4", stage:"Entrega  Inmediata"}, {name:"Fase 5", stage:"Entrega  Inmediata"}]],
    [PROJECTS.sente, [{name: "", stage: "Entrega Inmediata"}]],
    [PROJECTS.duplo, [{name: "", stage: "Vendido"}]],
    [PROJECTS.prana, [{name: "", stage: "Vendido"}]],
    [PROJECTS.prana, [{name: "", stage: "Vendido"}]],
])

export const PROJECTS_DATA = {
    [PROJECTS.midgo]: {...PROJECTS_CRM.midgo, name: ucFirst(PROJECTS.midgo), ubication: PROJECT_LOC.midgo, isActive: true},
    [PROJECTS.momen]: {...PROJECTS_CRM.momen, name: ucFirst(PROJECTS.momen), ubication: PROJECT_LOC.momen, isActive: true},
    [PROJECTS.ativa]: {...PROJECTS_CRM.ativa, name: ucFirst(PROJECTS.ativa), ubication: PROJECT_LOC.ativa, isActive: true},
    [PROJECTS.épiqe]: {...PROJECTS_CRM.épiqe, name: ucFirst(PROJECTS.épiqe), ubication: PROJECT_LOC.épiqe, isActive: true},
    [PROJECTS.muvin]: {...PROJECTS_CRM.muvin, name: ucFirst(PROJECTS.muvin), ubication: PROJECT_LOC.muvin, isActive: true},
    [PROJECTS.nesta]: {...PROJECTS_CRM.nesta, name: ucFirst(PROJECTS.nesta), ubication: PROJECT_LOC.nesta, isActive: true},
    // [PROJECTS.sente]: {...PROJECTS_CRM.sente, name: ucFirst(PROJECTS.sente), ubication: PROJECT_LOC.sente, isActive: false},
    [PROJECTS.duplo]: {...PROJECTS_CRM.duplo, name: ucFirst(PROJECTS.duplo), ubication: PROJECT_LOC.duplo, isActive: false},
    [PROJECTS.prana]: {...PROJECTS_CRM.prana, name: ucFirst(PROJECTS.prana), ubication: PROJECT_LOC.prana, isActive: false},
    [PROJECTS.velia]: {...PROJECTS_CRM.velia, name: ucFirst(PROJECTS.velia), ubication: PROJECT_LOC.velia, isActive: false},
}