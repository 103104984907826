class FacebookChat {
    /**
     * Create FacebookChat instance
     * 
     * This class handles the creation and initialization of the
     * facebook customer chat plugin within our app.
     * 
     * @param {String} appId
     * @param {String} pageId
     * 
     * @returns { FacebookChat }
     */
    constructor(appId, pageId) {
        this.appId = appId
        this.pageId = pageId

        this.sdkId = "facebook-jssdk"
        this.sdkSrc = "https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js"
        this.sdkElement = null
        this.chatElement = null
    }

    get chatInitFunc() {
        return () => {
            window.FB.init({
                xfbml: true,
                version: "v14.0",
                appId: this.appId,
                autoLogAppEvents: true
            })
        }
    }

    init() {
        if (window.isMobile()) {
            return
        }

        if (this.chatElement === null) {
            this.chatElement = document.body.appendChild(
                this.createFloatingDivElement()
            )
        }

        if (this.sdkElement !== null) {
            return
        }

        window.fbAsyncInit = this.chatInitFunc

        this.sdkElement = this.createScriptElement();
        
        (firstScriptElement => {
            firstScriptElement.parentNode.insertBefore(
                this.sdkElement, firstScriptElement
            )
        })(document.getElementsByTagName("script")[0])
    }

    /**
     * Create script element
     * 
     * @returns {HTMLScriptElement}
     */
    createScriptElement() {
        let scriptElement = document.createElement("script")

        scriptElement.id = this.sdkId
        scriptElement.src = this.sdkSrc
        scriptElement.setAttribute("page_id", this.pageId)
        return scriptElement
    }

    /**
     * Create floating messenger icon element
     * 
     * @returns {HTMLDivElement}
     */
    createFloatingDivElement() {
        let divElement = document.createElement("div")
        divElement.id = "fb-customer-chat"
        divElement.className = "fb-customerchat"
        divElement.setAttribute("page_id", this.pageId)
        divElement.setAttribute("attribution", "biz_inbox")
        return divElement
    }
}

export { FacebookChat }