import React, { Component } from 'react';
import Formulario from '../../component/contact/contact';
import './contactanos.scss'
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery';
import 'lightgallery/dist/js/lightgallery-all';
import $ from 'jquery'
const zoom = require("../../assets/images/contactanos/Tutorial-zoom.mp4").default 
const skype = require("../../assets/images/contactanos/Cosapi-skype.mp4").default 
export default class Contactanos extends Component {
    componentDidMount(){
        $('.d-flex').lightGallery({
            selector: '.item',
            thumbnail:true,
            animateThumb: false,
            showThumbByDefault: false,
            subHtmlSelectorRelative: true,
            rotate:false,
            actualSize:false,
            download:true,
            pinterest:false,
            googlePlus:false,
            twitter:false,
        });
        document.body.id="deletevideo"
    }
    render() {
    return (
        <main className="contactanos">
            <div className='container'>
                <section className='formulario'>
                    <div className='content-form'>
                        <h2 className="title gotham-bold c-secondary">¿Aún no haz agendado una cita para conocer tu nuevo depa?</h2>
                        <h2 className="subtitle gotham-book c-secondary">Deja tus datos en el siguiente formulario y conversemos</h2>
                        <Formulario></Formulario>
                    </div>
                    <div className='content-img'>
                        <img className='img' src={require('../../assets/images/contactanos/model-woman.png')} ></img>
                    </div>
                </section>
                <section className='section-video'>
                    <div className='container'>
                        <div className='d-flex container-video'>
                            <div className='content-text'>
                                <h2 className="title c-secondary">Prepárate para conocer<br></br> tu nuevo depa online</h2>
                                <p className='paragraph c-secondary'>Aprende con nosotros el <span className='bold'>paso a paso </span>de cómo tener una
                                videollamada (vía Zoom o Skype) con tu asesor para conocer a
                                detalle el departamento que estás buscando. ¡Elige la aplicación
                                que prefieras y empecemos!</p>
                            </div>
                            <div className='content-video'>
                                <div className='d-flex'>
                                <div style={{display:'none'}} id="video1">
                                    <video className="lg-video-objec lg-html5" controls preload="none">
                                        <source src={zoom} type="video/mp4" />
                                    </video>
                                </div>
                                <div style={{display:'none'}} id="video2">
                                    <video className="lg-video-objec lg-html5" controls preload="none">
                                        <source src={skype} type="video/mp4" />
                                    </video>
                                </div>
                                    <div className='item' data-html="#video1">
                                        <span className='title-item c-secondary gotham-bold'>¿Cómo usar Zoom?</span>
                                        <div className='content-img'>
                                            <img className='icon-play' src={require('../../assets/images/contactanos/play-tutorial.svg').default} ></img>
                                            <img className='img' src={require('../../assets/images/contactanos/img-zoom.jpg')} ></img>
                                        </div>
                                    </div>
                                    <div className='item' data-html="#video2">
                                        <span className='title-item c-secondary gotham-bold'>¿Cómo usar Skype?</span>
                                        <div className='content-img'>
                                            <img className='icon-play' src={require('../../assets/images/contactanos/play-tutorial.svg').default} ></img>
                                            <img className='img' src={require('../../assets/images/contactanos/img-zoom.jpg')} ></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
  }
}
