import React, { Component } from 'react'
import "./range.scss"
export default class Range extends Component {
    constructor(props){
        super(props) 
        this.getValue = this.getValue.bind(this)
        this.returnValue = this.returnValue.bind(this)
        this.state = {
            widthLine:0,
            input1:0,
            input2:0,
            inputLine:[],
            settingsRange:{
                min:0,
                max:3,
                step:1,
                getValue:this.getValue,
                returnValue:this.returnValue,
            },
        }
    }
    returnValue(value){
        console.log("input Line:",value)
        this.setState({inputLine:value})
    }
   
    async getValue(name,value){
        let data;
        if(name == "input1"){
            if(value > this.state.input2){
                data = await this.returnArray(value,this.state.input2)
                this.props.setting.getData(data)
                this.setState({input1:parseInt(value)})
            }
            if(value < this.state.input2){
                data = await this.returnArray(this.state.input2,value)
                this.props.setting.getData(data)
                this.setState({input1:parseInt(value)})
            }
        }else if(name == "input2"){
            if(value >= this.state.input1){
                data = await this.returnArray(value,this.state.input1)
                this.props.setting.getData(data)
                this.setState({input2:parseInt(value)})
            }
            if(value < this.state.input1){
                data = await this.returnArray(this.state.input1,value)
                this.props.setting.getData(data)
                this.setState({input2:parseInt(value)})
            }
        }   
    }
    returnArray(mayor,menor){
        var arrayItem = []
        let cont = 0
        for(var i = 0;i <= mayor;i++){
            if(i >= menor){
                arrayItem[cont] = i
                cont++
            }
        }
        // this.setState({inputLine:arrayItem})
        return arrayItem
        console.log(arrayItem)
        
    }
    render() {
        return (
            <div className="type_range">
                <RangeInput name={"input1"} setting={this.state.settingsRange}></RangeInput>
                <RangeInput name={"input2"} setting={this.state.settingsRange}></RangeInput>
                {/* <LineRange data={this.state.inputLine}></LineRange> */}
                {
                    this.props.children
                }
            </div>
        )
    }
}
class LineRange extends Component{
    constructor(props){
        super(props)
        console.log("line range: ",props)
    }
    render(){
        return(
        <div className={`line_range line-${this.props.data[0]} line-${this.props.data.pop()}`}>
            <div className="line_active_show"></div>                    
        </div>
        )
    } 
}
class RangeInput extends Component{
    constructor(props){
        super(props)
        this.state = {
            maxValue:this.props.setting.max,
            minValue:this.props.setting.min,
            step:this.props.setting.step,
            value:0,
        }
        this.circle = React.createRef();
        this.rangeSlider = React.createRef();
    }
    onDurationChange (thumb,e){
        let name = this.props.name
        let value = e.target.value
        let width = this.rangeSlider.current.clientWidth
        let circleWidth = this.circle.current.clientWidth
        thumb.current.style=`left:${width * (value / this.state.maxValue) - (value / this.state.maxValue) * circleWidth}px`
        this.setState({value:parseInt(value)})
        this.props.setting.getValue(name,value)
    }
    render() {
        return (
            <div className="content-input-range">
                {/* <input ref={this.rangeSlider} className="input_range range-slider"
                    type="range"
                    min={this.state.minValue}
                    max={this.state.maxValue}
                    step={this.state.step}
                    value={this.state.value}
                    onChange={(e)=>{this.onDurationChange(this.circle,e)}}
                /> */}
                <div ref={this.circle} className="circle"></div>
            </div>
        )
    }
}