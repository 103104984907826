import { useEffect, useState } from "react"
import OwlCarousel from "react-owl-carousel2"
import { FormContainer } from "../../component/common/forms/Form"
import { dataInicio } from "../../data/inicio"
import { FormMultiproducto } from "./formMultiproducto"
import { moveTo,events,offset,options, initAnimation, settingsCards } from "./script"
import $ from "jquery"
import MediaQuery from "react-responsive"
import Slider from "react-slick";

import './multiproducto.scss'
export default function Multiproducto(){
    useEffect(()=>{
        if(window.innerWidth > 500){
            initAnimation()
        }
    },[])
    return (
        <main className="multiproducto referidos">
            <HeaderMultiproduct />
            <section className='section-dreams'>
                <img className='hand hand-left' src={require('../../assets/images/multiproducto/hand-man.png')} ></img>
               
                <img className='hand hand-right' src={require('../../assets/images/multiproducto/hand-woman.png')} ></img>
                <div className='content' id="banner-hands">
                    <div className="mini-ball mini-ball-1"></div>
                    <div className="mini-ball mini-ball-2"></div>
                    <span className='title-banner c-white'>
                        ¡Estás a un paso de
                        <strong>cumplir tus sueños!</strong>
                    </span>
                    <div className='mask b-white down moveDown' onClick={()=>{moveTo(".moveDown",'#container-multiproducto')}}>
                    </div>
                </div>
            </section>
            <div className='container-multiproducto' id="container-multiproducto">
                <FormMultiproducto/>
            </div>
        </main>
    )
}


function HeaderMultiproduct(){
    const [position,setPosition] = useState(0);
    const [listBrands, setListBrands] = useState([
        {name:"ativa",img:require("../../assets/images/multiproducto/ATIVA.png")},
        {name:"epiqe",img:require("../../assets/images/multiproducto/EPIQE.png")},
        {name:"muvin",img:require("../../assets/images/multiproducto/MUVIN.png")},
        {name:"nesta",img:require("../../assets/images/multiproducto/nesta.png")},
        {name:"sente",img:require("../../assets/images/multiproducto/sente.png")},
    ])
    const moveSlider = (e) => {
        $(`.brand-${e}`).addClass("active").siblings().removeClass("active")
        let owl = $("#carousel-target").owlCarousel()
        owl.trigger('to.owl.carousel', [e, 300]);
    }

    return(
        <div className='container-multiproducto'>
            <section className='container'>
                <picture className="expand-img">
                    <source media="(min-width: 600px)" srcSet={require('../../assets/images/multiproducto/slider.png')}></source>
                    <img className='expand-img' src={require('../../assets/images/multiproducto/baner_mobile.png')} ></img>
                </picture>
            </section>
            <h2 className="text-center mt-4-xl mt-3-md mb-md-2 mb-sm-1 title-list-brands">Selecciona el proyecto de tu interés</h2>
            <div className='list-brands'>
                <MediaQuery minWidth={800}>
                {
                    listBrands.map((item, index) => {
                        return(
                            <div className={`item-brand-${item.name} brand brand-${index} ${index == 0 ? "active":""}`} key={index+"listbrands"} onClick={()=>{moveSlider(index)}}>
                                <img className="brand-img" src={item.img}></img>
                            </div>
                        )
                    })
                }
                </MediaQuery>
                <MediaQuery maxWidth={799}>
                    <Slider {...settingsCards} >
                        {
                            listBrands.map((item, index) => {
                                return(
                                    <div className={`item-brand-${item.name} brand brand-${index} ${index == 0 ? "active":""}`} key={index+"listbrands"} onClick={()=>{moveSlider(index)}}>
                                        <img className="brand-img" src={item.img}></img>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </MediaQuery>
            </div>
            <section className='section-target-slider container'>
                <OwlCarousel options={options} events={events} className="owl-carousel" id="carousel-target">
                    {
                        dataInicio.cardsFilter.map((item, index) => {
                            if(item.fase != "Vendido"){
                                return(
                                    <div className="target" key={index+"datainicio"}>
                                        <div className='content-img'>
                                            <img className='img-project' src={item.imgProject} ></img>
                                        </div>
                                        <div className='info'>
                                            <div className='header' id={"header-"+index}>
                                                <div className='content-brand'>
                                                    <img className='' src={item.imgBrand} ></img>
                                                </div>
                                                <MediaQuery minWidth={500}>
                                                    <span className='frase' dangerouslySetInnerHTML={{__html:item?.frase ? item?.frase : null}}></span>
                                                </MediaQuery>
                                            </div>
                                            <div className='fases' id={"fase-"+index}>
                                                {
                                                    item?.allFases ? 
                                                    item?.allFases.map((e, index) => {
                                                        return(
                                                            <div className='fase d-flex justify-center align-center' key={index+"allfases"}>
                                                                <span className='sub-title'>{e.fase}</span>
                                                                <span className='title'>{e.type}</span>
                                                            </div>
                                                        )
                                                    }) : null
                                                }
                                            </div>
                                            <div className='address' dangerouslySetInnerHTML={{__html:item?.address+", "+item.district}}>
                                            </div>
                                            <MediaQuery maxWidth={499}>
                                                <span className='frase' dangerouslySetInnerHTML={{__html:item?.frase ? item?.frase : null}}></span>
                                            </MediaQuery>
                                            <div className='data d-flex'>
                                                <div className='dorms d-flex align-center'>
                                                    <div className='icon d-flex align-center justify-center'>
                                                        <div className="mask bed"></div>
                                                    </div>
                                                    <div className='d-flex f-column'>
                                                        <span className='title-data'>
                                                            { item?.dorms}
                                                        </span>
                                                        <span className='sub-title'>DORMITORIOS</span>
                                                    </div>
                                                </div>
                                                <div className='meters d-flex align-center'>
                                                    <div className='icon d-flex align-center justify-center'>
                                                        <div className="mask croquis"></div>
                                                    </div>
                                                    <div className='d-flex f-column'>
                                                        <span className='title-data'>
                                                            { item?.meters}
                                                        </span>
                                                        <span className='sub-title'>METRAJE</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='link' id={"link-"+index}>
                                                Ver depas
                                                <img className='icon-right' src={require('../../assets/images/icons/right.svg').default} ></img>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                    
                </OwlCarousel>
            </section>
        </div>
    )
}