import React, { Component } from 'react'

export default class Card extends Component {
    render() {
        return (
            <div className="slide card">
                
            </div>
        )
    }
}
