import React, { Component, useEffect, useState } from 'react'
import "./footer.scss"
export default function Footer(){
    const [typeDevice, setTypeDevice] = useState('android')
    useEffect(()=>{
        function esDispositivoiOS(){
            var userAgent = window.navigator.userAgent;
            return (/iP(hone|od|ad)/.test(userAgent));
        }
        setTypeDevice(esDispositivoiOS() ? 'ios' : 'android')
    },[])
    return (
        <footer className={`footer ${typeDevice}`}>
            <div className="container">
                <div className="row">
                    <div className="left">
                        <div className="column">
                            <span className="title-column c-white">
                                Empresa promotora inmobiliaria de
                            </span>
                            <a target="_blank" href="https://www.cosapi.com.pe/site/index.aspx">
                                <img className="h-12" src={require("../../assets/images/icons/background-footer-cosapi.svg").default}></img>
                            </a>
                        </div>
                        <div className="line"></div>
                        <div className="column">
                            <span className="title-column c-white">
                                Inmobiliaria certificada por
                            </span>
                            <a target="_blank" href="https://www.bestplacetolive.com.pe/">
                                <img className="img-column" src={require("../../assets/images/icons/best-place.svg").default}></img>
                            </a>
                        </div>
                        <div className="line"></div>
                        <div className="column miembro">
                            <span className="title-column c-white">
                                Miembro de
                                <br></br>
                            </span>
                            <div className="content-item">
                                <a target="_blank" href="https://adiperu.pe/"><img className="img-column adi" src={require("../../assets/images/icons/adi-peru.svg").default}></img></a>
                                <a target="_blank" href="https://dci.pe/"><img className="img-column dci" src={require("../../assets/images/icons/dci-white.png")}></img></a>
                                <a target="_blank" href="https://asei.com.pe/"><img className="img-column asei" src={require("../../assets/images/icons/asei.png")}></img></a>

                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="column">
                            <span className="title-column c-white">
                                Síguenos
                                <br></br>
                            </span>
                            <div className="content-redes">
                                <a target="_blank" href="https://www.facebook.com/cosapiinmobiliariaoficial/" className="redes facebook"></a>
                                <a target="_blank" href="https://www.instagram.com/cosapi.inmobiliaria/" className="redes instagram"></a>
                                <a target="_blank" href="https://www.youtube.com/channel/UCfr9B6LMPgZDI6LVSsIA-yQ" className="redes youtube"></a>
                                <a target="_blank" href="https://pe.linkedin.com/company/cosapiinmobiliaria" className="redes linkdin"></a>
                                <a target="_blank" href="https://www.tiktok.com/@cosapi.inmobiliaria" className="redes tiktok bg-white"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <span className="c-white">© 2022 Cosapi Inmobiliaria. Todos los derechos reservados</span>
                    <span className="c-white">
                        Created with ♥ by <a target="_blank" className="c-white" href="https://formulaperu.com/">Fórmula</a>
                    </span>
                </div>
            </div>
        </footer>
    )
}
