import React, { Component } from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import "./BuscandoDepa.scss"
import { checkableBoolProps, FormContainer, setInputProps } from '../../component/common/forms/Form';
import { FAQContactValidatonSchema } from '../../component/common/forms/constraints/ValidatonSchemas';
import { MailService } from '../../component/common/Http/MailService';
import { BuscaDepa } from '../../component/common/Mails/buscadepa';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { active } from '../../data/modal';
class BuscandoDepa extends Component {
    constructor(props){
        super(props)
        this.mailService = new MailService()
        this.submitForm = this.submitForm.bind(this)
        this.activeModal = this.activeModal.bind(this)
        this.state = {
            expanded:'',
            icon:`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38"><defs><style>.cls-1{fill:#eee;}.cls-2{fill:none;stroke:#707070;stroke-linecap:round;stroke-width:2px;}</style></defs><title>Recurso 1laptop</title><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M19,0q.93,0,1.86.09c.62.06,1.24.15,1.85.27s1.21.28,1.81.46a18.25,18.25,0,0,1,1.75.63A17.31,17.31,0,0,1,28,2.24c.55.3,1.08.62,1.6,1s1,.72,1.49,1.11.94.82,1.38,1.25A18.37,18.37,0,0,1,33.69,7c.39.48.76,1,1.11,1.49s.66,1.05,1,1.6a17.31,17.31,0,0,1,.79,1.69,18.25,18.25,0,0,1,.63,1.75c.18.6.33,1.2.45,1.81a16.52,16.52,0,0,1,.28,1.85Q38,18.07,38,19t-.09,1.86a16.52,16.52,0,0,1-.28,1.85c-.12.61-.27,1.21-.45,1.81a18.25,18.25,0,0,1-.63,1.75A17.31,17.31,0,0,1,35.76,28c-.3.55-.62,1.08-1,1.6s-.72,1-1.11,1.49-.81.94-1.26,1.39-.9.85-1.38,1.25-1,.76-1.49,1.11-1,.66-1.6,1a17.31,17.31,0,0,1-1.69.79,18.25,18.25,0,0,1-1.75.63c-.6.18-1.2.33-1.81.45a16.52,16.52,0,0,1-1.85.28Q19.93,38,19,38t-1.86-.09a16.52,16.52,0,0,1-1.85-.28c-.61-.12-1.21-.27-1.81-.45a18.25,18.25,0,0,1-1.75-.63A17.31,17.31,0,0,1,10,35.76c-.55-.3-1.08-.62-1.6-1s-1-.72-1.49-1.11-1-.81-1.39-1.25-.85-.91-1.25-1.39-.76-1-1.11-1.49-.66-1-1-1.6a17.31,17.31,0,0,1-.79-1.69,18.25,18.25,0,0,1-.63-1.75c-.18-.6-.33-1.2-.45-1.81a16.52,16.52,0,0,1-.28-1.85Q0,19.93,0,19t.09-1.86a16.52,16.52,0,0,1,.28-1.85c.12-.61.27-1.21.45-1.81a18.25,18.25,0,0,1,.63-1.75A17.31,17.31,0,0,1,2.24,10c.3-.55.62-1.08,1-1.6s.72-1,1.11-1.49.81-1,1.25-1.39S6.47,4.71,7,4.31s1-.76,1.49-1.11,1.05-.66,1.6-1a17.31,17.31,0,0,1,1.69-.79A18.25,18.25,0,0,1,13.48.82c.6-.18,1.2-.33,1.81-.46S16.52.15,17.14.09,18.38,0,19,0Z"/><line class="cls-2 line-transform" x1="19.5" y1="12.5" x2="19.5" y2="25.77" style="&#10;"/><line class="cls-2" x1="25.77" y1="19.5" x2="12.5" y2="19.5"/></g></g></svg>`
        }
    }
    handleChange = (panel) => (event, newExpanded) => {
        this.setState({expanded: newExpanded ? panel : false})
    }
    submitForm(values, { setSubmitting, resetForm }) {
        this.mailService.from("Web Cosapi Inmobiliaria <no-reply@formulaperu.com>")
            // .to("sistemas@formulaperu.com")
            .to("informes@cosapiinmobiliaria.com.pe,cosapi.inmobiliaria@gmail.com")
            .subject("Notificación Cosapi Inmobiliaria")
            .html(BuscaDepa(values))

        this.mailService.send()
        .then(r=>{
            Swal.fire({
                title: '',
                text: 'Muchas Gracias, un asesor te contactará pronto',
                icon: 'success',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'var(--primary)'
            })
        })
        .catch(e=>{
            Swal.fire({
                title: '',
                text: '¡Error! Intentelo más tarde',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'red'
            })
        })
        .finally(_=>setSubmitting(false))
    }
    initialValues = {
        fname: "",
        lname: "",
        email: "",
        telephone: "",
        message: "",
        terms: false,
    }
    activeModal(){
        console.log("active modal")
        this.setState({modal:!this.state.modal})
    }
    render(){
        return (
            <main className="page-atencion pt-3 client">
                <div className="mini-ball mini-ball-1"></div>
                <div className="mini-ball mini-ball-2"></div>
                <div className="contain">
                    <div className="content-header">
                        <div className="content-img">
                            <img src={require("../../assets/images/atencion/buscando-depa-page.png")}></img>
                        </div>
                        <div className="content-text">
                            <div className="contain-text">
                                <h1 className="c-white title">Estoy buscando un depa </h1>
                                <span className="title-2 c-white">Preguntas frecuentes</span>

                                <p className="paragraph c-white">
                                    ¿Tienes alguna duda? Busca en nuestra
                                lista de preguntas frecuentes y
                                encontremos juntos una solución
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="questions">
                        <div className="column">
                            <Accordion expanded={this.state.expanded === 'panel1'} square onChange={this.handleChange('panel1',this)}>
                                <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                                    <Typography><div className="icon" dangerouslySetInnerHTML={{__html:this.state.icon}}></div><p className="gotham-book c-secondary title-acordeon">¿Cuál es el beneficio de comprar un departamento en Preventa?</p></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <p className="paragraph c-secondary">
                                        <span className="gotham-bold">A continuación, te comentamos los principales beneficios de adquirir un departamento en pre venta:</span><br></br>
                                        - Serás el primero en elegir la ubicación de tu departamento. <br></br>
                                        - Contarás con mayores beneficios en base a descuentos
                                        adicionales y promociones únicas e irrepetibles.
                                       </p>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={this.state.expanded === 'panel2'} square onChange={this.handleChange('panel2',this)}>
                                <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                                    <Typography><div className="icon" dangerouslySetInnerHTML={{__html:this.state.icon}}></div><p className="gotham-book c-secondary title-acordeon">¿Qué debo hacer si saco el préstamo mediante un Banco diferente al que financia el proyecto?</p></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <p className="paragraph c-secondary">
                                        Recuerda que es importante conocer cuál es el banco promotor del proyecto y el respaldo que éste genera sobre su futura propiedad.
                                        </p>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={this.state.expanded === 'panel3'} square onChange={this.handleChange('panel3',this)}>
                                <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                                    <Typography><div className="icon" dangerouslySetInnerHTML={{__html:this.state.icon}}></div><p className="gotham-book c-secondary title-acordeon">¿Qué es y cuál es el monto de la Cuota Inicial? ¿Se puede pagar en partes?</p></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <p className="paragraph c-secondary">
                                        La cuota inicial es el monto, solicitado por la entidad bancaria que otorgará el préstamo como parte del pago, que el cliente brindará para la adquisición de su departamento.
                                        </p>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={this.state.expanded === 'panel4'} square onChange={this.handleChange('panel4',this)}>
                                <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                                    <Typography><div className="icon" dangerouslySetInnerHTML={{__html:this.state.icon}}></div><p className="gotham-book c-secondary title-acordeon">¿Cosapi Inmobiliaria brinda algún tipo de garantía al adquirir el departamento?</p></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <p className="paragraph c-secondary">
                                        Cosapi Inmobiliaria y sus socios son propietarios del terreno donde se desarrolla el proyecto. El suelo y la construcción que se realiza, son la garantía con la que cuenta el proyecto. Asimismo, como respaldo adicional, todo proyecto es desarrollado con un banco promotor.                                        </p>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={this.state.expanded === 'panel5'} square onChange={this.handleChange('panel5',this)}>
                                <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                                    <Typography><div className="icon" dangerouslySetInnerHTML={{__html:this.state.icon}}></div><p className="gotham-book c-secondary title-acordeon">¿Quién se encarga de inscribir el departamento a mi nombre en los Registros Públicos?</p></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <p className="paragraph c-secondary">
                                        Cosapi Inmobiliaria, en coordinación con el cliente.
                                        </p>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                           
                        </div>
                        <div className="column">
                            <Accordion expanded={this.state.expanded === 'panel6'} square onChange={this.handleChange('panel6',this)}>
                                <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                                    <Typography><div className="icon" dangerouslySetInnerHTML={{__html:this.state.icon}}></div><p className="gotham-book c-secondary title-acordeon">Si cuento con nacionalidad peruana pero resido en el extranjero ¿tengo la posibilidad de adquirir un departamento?</p></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <p className="paragraph c-secondary">
                                        Siempre es posible adquirir un departamento, si vive en el extranjero puede pagar la inicial y el préstamo sacarlo con su banco local extranjero que le puede dar beneficios de localidad
                                        </p>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={this.state.expanded === 'panel7'} square onChange={this.handleChange('panel7',this)}>
                                <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                                    <Typography><div className="icon" dangerouslySetInnerHTML={{__html:this.state.icon}}></div><p className="gotham-book c-secondary title-acordeon">¿En qué me favorece el fondo Mivivienda?</p></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <p className="paragraph c-secondary">
                                        El Fondo Mivivienda es la entidad pública que promueve los inmuebles que cuentan con subsidio del estado, haciendo que la cuota mensual sea menor. En caso el proyecto adicional a Mivivienda cuenta con el certificado de bono verde, este le permitirá contar con una tasa preferencial dependiendo del rango de la certificación, asimismo si el departamento vale menos de S/ 316,000.00, el estado dará un subsidio adicional.
                                        </p>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={this.state.expanded === 'panel8'} square onChange={this.handleChange('panel8',this)}>
                                <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                                    <Typography><div className="icon" dangerouslySetInnerHTML={{__html:this.state.icon}}></div><p className="gotham-book c-secondary title-acordeon">¿A qué se refieren con financiamiento directo?</p></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <p className="paragraph c-secondary">
                                        Financiamiento directo o crédito directo, se refiere a la compra que puede hacer el cliente con la inmobiliaria sin vínculo del banco, en ese sentido el cliente se compromete a cancelar el íntegro del departamento hasta la entrega del inmueble en base a pagos proporcionales.
                                        </p>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={this.state.expanded === 'panel9'} square onChange={this.handleChange('panel9',this)}>
                                <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                                    <Typography><div className="icon" dangerouslySetInnerHTML={{__html:this.state.icon}}></div><p className="gotham-book c-secondary title-acordeon">¿Cómo puedo adquirir mi departamento con mi AFP?</p></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <p className="paragraph c-secondary">
                                        El estado permite que los ahorros de la AFP puedan ser utilizados para la compra de la primera vivienda. En ese sentido, se podrá hacer uso del 25% de la AFP, del ingreso familiar. Esto permitirá reducir el pago de la cuota inicial o pago de cuota mensual respectivamente.
                                        </p>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                    <section className="formulario">
                        <FormContainer initialValues={this.initialValues} validationSchema={FAQContactValidatonSchema} onSubmit={this.submitForm}>
                            {form => {const {handleSubmit, isSubmitting} = form;
                            console.log(isSubmitting)
                            return(
                            <form className="form" onSubmit={handleSubmit}>
                                <span className="title-2 gotham-book c-secondary">Si tienes alguna duda extra, te invitamos a</span>
                                <span className="title gotham-bold c-secondary">llenar este formulario y nos pondremos en contacto contigo</span>
                                <div className="d-flex">
                                    <div className="content-inputs">
                                        <input placeholder="Nombre" {...setInputProps("fname", "input", form)}></input>
                                        <input placeholder="Apellidos" {...setInputProps("lname", "input", form)}></input>
                                        <input placeholder="Teléfono de contacto" {...setInputProps("telephone", "input-line", form)}></input>
                                        <input placeholder="Correo electrónico" {...setInputProps("email", "input-line", form)}></input>
                                    </div>
                                    <div className="content-textarea">
                                        <textarea {...setInputProps("message", "textarea", form, true)}></textarea>
                                    </div>
                                </div>
                                <div className="politicas">
                                    <input id="terms" type="checkbox" {...checkableBoolProps("terms", "", form)}></input>
                                    <label htmlFor="terms" className="label-accept"><div className="check"></div></label>
                                    <span className="terms">Acepto las <a target="_blank" href={require("../../assets/files/Politica-de-privacidad.pdf")}>políticas de privacidad</a></span>
                                </div>
                                <button type="submit" className="btn-submit btn-radio c-white b-primary" disabled={isSubmitting}>{isSubmitting ? "Enviando" : "Enviar"}</button>
                            </form>)}}
                        </FormContainer>
                    </section>
                </div>
            </main>
        )}
}
function mapStateProps(state){
    return{
        value:state.modalSlice.value
    }
}
export default connect(mapStateProps,{active})(BuscandoDepa)