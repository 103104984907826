import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    data:[
        {
            title:"Elige la ubicación",
            paragraph:"Como primer paso es importante tener definido dónde desean vivir. Es importante que la ubicación tenga relación con su estilo de vida.",
            img:require("../assets/images/inversion/man-and-woman-sitting-on-sofa-with-laptop.png"),
        },
        {
            title:"Determina el número de dormitorios",
            paragraph:"Identifica la cantidad de dormitorios o ambientes para este primer gran paso. Si son una pareja primeriza, les sugerimos departamentos de 1 o 2 dormitorios, ya que pueden hacer que sus sueños crezcan poco a poco como su futuro hogar. Es importante, en este caso, tomar en cuenta que ir directamente al departamento más grande puede condicionar muchos pagos y probablemente limitarlos en sus sueños futuros.",
            img:require("../assets/images/inversion/long-shot-beautiful-family-spending-time-together-at-home-with-copy-space.png"),
        },
        {
            title:"Conoce tu capacidad crediticia",
            paragraph:"Para conocer tu capacidad crediticia, puedes acercarte a un funcionario bancario y ver si la cuota inicial (con la que cuentas) más tus ingresos mensuales, te permitirán acceder al crédito hipotecario. Si en caso no conoces a un asesor bancario, no te preocupes que nosotros podemos ayudarte.",
            img:require("../assets/images/inversion/Reformen-KV-und-Detailhandel-2022.png"),
        },
        {
            title:"Selecciona tu proyecto",
            img:require("../assets/images/inversion/pareja-con-laptop.jpg"),
            paragraph:"Identifica el proyecto (edificio) que más te guste. Es importante valorar el respaldo de la inmobiliaria, las áreas sociales, la distribución de los departamentos y diferentes atractivos que te ofrece el proyecto.",
        },
        {
            title:"Define el departamento",
            paragraph:"Elige con tu pareja o solo la distribución del departamento que más les guste y sepáralo.",
            img:require("../assets/images/inversion/TX03-vista-sala-comedor.png"),
        },
        {
            title:"Habla con nuestros asesores",
            paragraph:"Proporciona al asesor inmobiliario toda la información completa para que pueda validar la calificación bancaria, ten a la mano DNI, boletas de ingresos, monto de inicial, AFP que tengas en cuenta y si cuentas con empresa los últimos PDT.",
            img:require("../assets/images/inversion/business-woman-in-checkered-jacket-with-smile-while-sitting-at-desk-in-her-office.png"),
        },
        {
            title:"Abona la inicial",
            paragraph:"Una vez se cuenta con la precalificación, abona la inicial correspondiente y firma la minuta compra venta.",
            img:require("../assets/images/inversion/portrait-beautiful-young-asian-woman-use-credit-card-with-laptop-for-online-shopping.png"),
        },
        {
            title:"Realiza el desembolso",
            paragraph:"Para el desembolso hipotecario, el banco los contactará para las firmas del contrato hipotecario y pagos para estudios de títulos.",
            img:require("../assets/images/inversion/smiling-confident-colleagues-examining-document-together.png"),
        },
        {
            title:"Registra tu nuevo depa",
            paragraph:"Una vez se cuente con el contrato hipotecario, deberá acercarse a notaria para las firmas y realizar los pagos registrales y notariales.",
            img:require("../assets/images/inversion/businessman-examining-papers-at-table.png"),
        },

    ]
}
export const inversionStore = createSlice({
    name:"inversion",
    initialState,
    reducers:{

    }
}) 

export default inversionStore.reducer 