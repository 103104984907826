import React, { Component } from 'react'
import {FormContainer, setInputProps, checkableBoolProps} from "./../common/forms/Form"
import { HomeContactValidatonSchema } from '../common/forms/constraints/ValidatonSchemas'
import { PROJECTS_CRM, PROJECTS_DATA, PROJECT_SELLERS } from '../../data/projects'
import {SperantService} from "../common/Http/SperantService"
import Swal from 'sweetalert2'
import { connect } from 'react-redux'
import { active } from '../../data/modal'
import { LeadService } from '../common/Http/LeadService'

class Formulario extends Component {
    constructor(props){
        super(props)
        this.submitForm = this.submitForm.bind(this)
        this.sperantService = new SperantService()
        this.closeModal = this.closeModal.bind(this)
        this.state = {
            activeModal:false,
        }
    }
    onSuccess = seller_phone =>{
        this.setState({activeModal:true, sellerPhone: seller_phone})
    }
    onError = _ =>{
        Swal.fire({
            title: '',
            text: '¡Error! Intentelo más tarde',
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'var(--primary)'
        })
    }
    
    submitForm(values, { resetForm }) {
        const leadService = new LeadService()
        const project = PROJECTS_DATA[values.project]

        Object.assign(values, {
            input_channel_id: project.input_channels.web,
            source_id: project.source_ids.web
        })

        leadService
            .setProject(project)
            .setLeadData(values)
            .save().then(client =>{
                window.fbq && window.fbq('track', 'Lead', {project: project.name})
                this.onSuccess(client.last_agent_assigned.phone)
                resetForm()
            })
            .catch(e=>this.onError)
    }
    initialValues = {
        fname: "",
        lname: "",
        email: "",
        telephone: "",
        motive: "",
        project: "",
        time_to_contact: "",
        terms: false,
    }
    closeModal(){
        this.setState({activeModal:false})
    }
    render() {
        return (
            <FormContainer
            initialValues={this.initialValues} validationSchema={HomeContactValidatonSchema} onSubmit={this.submitForm}
        >
            {form => {const {handleSubmit, isSubmitting} = form;
            // {console.error(form.errors)}
            return(
            <form className="form" onSubmit={handleSubmit}>
                <div className={`popup ${this.state.activeModal ? "active" : ""}`}>
                    <div className="content-popup">
                        <div className="btn-close" onClick={()=>{this.closeModal()}}><img src={require("../../assets/images/icons/btn-close.svg").default}></img></div>
                        <img className="background desktop" src={require("../../assets/images/icons/background-modal.png")}></img>
                        <img className="background movil" src={require("../../assets/images/icons/background-modal-movil.png")}></img>
                        <img className="icon-check" src={require("../../assets/images/icons/check_icon.svg").default}></img>
                        <span className="title">¡Gracias!</span>
                        <span className="sub-title">Dentro de poco un asesor se pondrá en contacto contigo.</span>
                        {this.state.sellerPhone && <>
                        <span className="sub-title bold">También puedes contactarlo aquí</span>
                        <div className="contact">
                            <a href={`https://wa.me/51${this.state.sellerPhone}`} target="_blank"><img className="icon icon-whatsapp" src={require("../../assets/images/icons/whatsapp-white.svg").default}></img></a>
                            <a href={`tel:${this.state.sellerPhone}`} target="_blank"><img className="icon" src={require("../../assets/images/icons/call-white.svg").default}></img></a>
                        </div>
                        </>}
                    </div>
                </div>
                <div className="content-inputs">
                    <input placeholder="Nombre" {...setInputProps("fname", "", form)}></input>
                    <input placeholder="Apellidos" {...setInputProps("lname", "", form)}></input>
                    <input placeholder="Telefóno" {...setInputProps("telephone", "", form)}></input>
                    <input placeholder="Correo electrónico" {...setInputProps("email", "", form)}></input>
                    <select {...setInputProps("project", "", form)}>
                        <option value="">Selecciona tu proyecto</option>
                        {Object.keys(PROJECTS_DATA).map((key, i)=>
                            PROJECTS_DATA[key].allowLeadCreation && <option key={i} value={key}>{PROJECTS_DATA[key].name}</option>
                        )}
                    </select>
                    <select {...setInputProps("motive", "", form)}>
                        <option value="">Motivo de compra</option>
                        <option value="Primera Vivienda">Primera Vivienda</option>
                        <option value="Inversión">Inversión</option>
                    </select>
                    <select {...setInputProps("time_to_contact", "w-100", form)}>
                        <option value="">*Selecciona un horario para que un asesor te pueda llamar</option>
                        <option value="10:00 am - 11:00 am">10:00 am - 11:00 am</option>
                        <option value="11:00 am - 12:00 pm">11:00 am - 12:00 pm</option>
                        <option value="12:00 pm - 1:00 pm">12:00 pm - 1:00 pm</option>
                        <option value="1:00 pm - 2:00 pm">1:00 pm - 2:00 pm</option>
                        <option value="2:00 pm - 3:00 pm">2:00 pm - 3:00 pm</option>
                        <option value="3:00 pm - 4:00 pm">3:00 pm - 4:00 pm</option>
                        <option value="4:00 pm - 5:00 pm">4:00 pm - 5:00 pm</option>
                        <option value="5:00 pm - 6:00 pm">5:00 pm - 6:00 pm</option>
                        <option value="6:00 pm - 7:00 pm">6:00 pm - 7:00 pm</option>
                    </select>
                </div>
                <div className="politicas">
                    {/* <Field id="terms" type="checkbox" name="terms" /> */}
                    <input id="terms" type="checkbox" {...checkableBoolProps("terms", "", form)}></input>
                    <label htmlFor="terms" className="label-accept"><div className="check"></div></label>
                    <span className="terms">Acepto las <a target="_blank" href={require("../../assets/files/Politica-de-privacidad.pdf")}>políticas de privacidad</a></span>
                </div>
                <button type="submit" className="btn-submit btn-radio c-white b-primary" disabled={isSubmitting}>{isSubmitting ? 'Enviando...' : 'Enviar'}</button>
            </form>)}}
            </FormContainer>
        )
    }
}
function mapStateProps(state){
    return{
        value:state.modalSlice.value
    }
}
export default connect(mapStateProps,{active})(Formulario)