export const ReferrerMail = ({owner, referred, project}) => `
<table align="center" border="0" cellpadding="0" cellspacing="0" style="background-color: white;">
	<tbody>
        <tr>
	        <td style="word-wrap:break-word;font-size:0;padding:10px 25px;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0" align="center">
				<img src="https://cosapiinmobiliaria.com.pe/images/mail/family_n_friends.png">
	        </td>
        </tr>
        <tr>
	        <td style="word-wrap:break-word;font-size:0;padding:10px 25px;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0" align="center">
				<img src="">
	        </td>
        </tr>
		<tr>
			<td style="text-align: center;font-size: 1.3em;font-family: sans-serif;color: gray;">
				<p style="margin-bottom:0;">¡Hola ${owner.fname} ${owner.lname},</p>
				<p style="margin-top:0;"><b>gracias por referirnos</b>!</p>

				<div style="background-color: #d3d3d380;width: 73%;margin: auto;padding: 1em;border-radius: 15px;text-align: initial;">
					<p style="margin: 0;font-size: .6em;">DATOS DE TU REFERIDO:</p>
					<p style="margin:0;margin-top: 1em;">Nombres: ${referred.fname}</p>
					<p style="margin:0;">Apellidos: ${referred.lname}</p>
					<p style="margin:0;">Correo: ${referred.email}</p>
					<p style="margin:0;">DNI: ${referred.document}</p>
					<p style="margin:0;">Celular: ${referred.telephone}</p>
				</div>

				<p style="margin-bottom:0;">Pronto un asesor se contactará con tu</p>
				<p style="margin:0;">referido para brindarle mayor información del</p>
				<p style="margin-top:0;margin-bottom:1em;"><b>proyecto ${project}</b></p>
			</td>
		</tr>
		<tr>
			<td style="word-wrap:break-word;font-size:0;padding:10px 25px;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0" align="center">
				<img src="https://cosapiinmobiliaria.com.pe/images/mail/gift_card.png">
			</td>
		</tr>
		 <tr>
	        <td style="word-wrap:break-word;font-size:0;padding:10px 25px;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0" align="center">
				<img src="https://cosapiinmobiliaria.com.pe/images/mail/footer.png" usemap="#image-map">
					<map name="image-map">
					    <area target="_blank" alt="Depa de tus sueños" title="Depa de tus sueños" href="https://eldepadetussueños.pe" coords="27,194,289,238" shape="rect">
					    <area target="_blank" alt="Web" title="Web" href="https://www.cosapiinmobiliaria.com.pe" coords="293,196,570,237" shape="rect">
					    <area target="_blank" alt="Facebook" title="Facebook" href="https://www.facebook.com/cosapiinmobiliariaoficial/" coords="341,132,389,184" shape="rect">
					    <area target="_blank" alt="Instagram" title="Instagram" href="https://www.instagram.com/cosapi.inmobiliaria/" coords="393,132,446,185" shape="rect">
					    <area target="_blank" alt="Youtube" title="Youtube" href="https://www.youtube.com/channel/UCfr9B6LMPgZDI6LVSsIA-yQ" coords="451,133,498,184" shape="rect">
					    <area target="_blank" alt="Linkedin" title="Linkedin" href="https://www.linkedin.com/company/cosapiinmobiliaria/?originalSubdomain=pe" coords="508,133,561,185" shape="rect">
					    <area target="_blank" alt="Adiperu" title="Adiperu" href="https://adiperu.pe/asociados/cosapi/" coords="244,20,423,116" shape="rect">
					    <area target="_blank" alt="DCI" title="DCI" href="https://dci.pe/miembros-dci/" coords="435,18,550,117" shape="rect">
					    <area target="_blank" alt="BEST PLACE TO LIVE" title="BEST PLACE TO LIVE" href="https://www.bestplacetolive.com.pe/inmobiliaria_cosapi/" coords="32,16,220,122" shape="rect">
					</map>
	        </td>
        </tr>

		</tbody>
</table>
`