import axios from "axios"
import { PROJECTS, SPERANT_ENV } from "../../../data/projects"

export class SperantService {
  constructor() {
    this.api = axios.create({
      baseURL: "https://cors.formulaperu.com/https://api.sperant.com/v3/",
      headers: {
        "Cache-Control": "no-cache",
      },
    })
    // this.interceptors()
  }

  api_keys = {
      [SPERANT_ENV.cosapi]: "h9G7MwHYHIaqfLSrMLiod7u6Nj9fiHG63hCv57ay",
      [SPERANT_ENV.cosapilar]: "mQlbKvZAlc5MpKwKs3AHr3r9v2MbhvbA1E4pX2Mw",
  }

  environment(name){
    let key = this.api_keys[name]
    this.api.defaults.headers.common['Authorization'] = key
    return this
  }

  interceptors() {
    this.api.interceptors.response.use(
      response => {
        if (window.dataLayer) {
          if (response.config.url === "/clients") {
            const reqBody = JSON.parse(response.config.data)
            const project = this.getProjectName(reqBody.data_related)
            window.dataLayer.push({ event: "gaEvent", eventCategory: "Lead", eventAction: "Submit",  eventLabel: project })
          }
        }
        // console.log("Response Interceptor: ", response)
        return response
      },
      error => {
        return Promise.reject(error)
      }
    )
  }
  
  createClient(client) {    
    return this.api.post("/clients", client)
  }

  projects(id = "") {
    return this.api.get(`/projects/${id}`)
  }

  users(id = "") {
    return this.api.get(`/users/${id}`)
  }

  types(parameters) {
    return this.api.get("/types", parameters)
  }

  createBudget(budget) {
    new URLSearchParams(localStorage.getItem("url_query")).forEach((v, k) => {
      /utm_/.test(k) && (budget[`${k.toLowerCase()}`] = v)
    })
    return this.api.post("/budgets", budget)
  }
}