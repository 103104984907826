import React, { Component } from 'react'
import './inicio.scss'
import Slider from "react-slick";
import Footer from '../../component/footer/footer';
import Card from '../../component/card/card';
import Formulario from '../../component/contact/contact';
import Displaceable from 'displaceable';
import Filter from '../../component/filter/filter';
import { PROJECTS_CRM } from '../../data/projects';
import Popup from '../../component/popup/popup'
import { FacebookChat } from '../../component/common/FacebookChat';
// import MediaQuery from 'react-responsive/types/Component';


export default class Inicio extends Component {
    constructor(props){
        super(props)
        this.changeSlider = this.changeSlider.bind(this)
        this.afterChange = this.afterChange.bind(this)
        this.moveTo = this.moveTo.bind(this)
        this.offset = this.offset.bind(this)
        this.activeModal = this.activeModal.bind(this)
        this.activateAutoplay = this.activateAutoplay.bind(this)
        this.slider = React.createRef();
        this.state = {
            cardFilter:this.props.data.cardsFilter,
            sliderMain: [],
            activeSlider:0,
            position:1,
            input1:0,
            input2:0,
            dorms:[],
            modal:false,
            videoMuted: true,
            settings:{
                dots: false,
                arrows:false,
                infinite: true,
                autoplay:true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                pauseOnHover:false,
                autoplaySpeed: 5000,
                afterChange:this.afterChange
            },
            settingsRange:{
                min:0,
                max:3,
                step:1,
                getValue:this.getValue
            }
        }
        this.$$facebookChat = new FacebookChat(
            "176165019891976", "823525821009119"
        );

        this.$$facebookChat.init();
    }
    activeModal(){
        console.log("active modal")
        this.setState({modal:!this.state.modal})
    }
    componentDidMount(){
        const slides = !window.isMobile() ? this.props.data.sliderMain : this.props.data.sliderMain.filter(e=>!e.video)
        this.setState({sliderMain:slides})
        slides[0].video && this.darkenNav()
        const displaceable = new Displaceable(document.getElementById('laptop'),{
            displaceFactor: .5,
            skewFactor:.5,
            resetTime: 200,
        });
        
            
    }
    getValue(e){
        if(e.name == "input1"){
            this.setState({input1:e[e.name]})
        }
        if(e.name == "input2"){
            this.setState({input2:e[e.name]})
        }
    }
    afterChange(e){
        this.setState({activeSlider:e})
    }
    activateAutoplay(){
        this.setState({
            settings: {
                ...this.state.settings,
                autoplay: true,
            }
        })
    }
    changeSlider(e, position){
        if(position == 0) this.darkenNav()
        else this.setTransparentNav()
        this?.slider?.current.slickGoTo(position)
    }
    darkenNav(){
        !window.isMobile() && document.querySelector(".navbar").classList.add("dark")
    }
    setTransparentNav(){
        !window.isMobile() && document.querySelector(".navbar").classList.remove("dark")
    }
    moveTo(elementFrom,elementTo, project){
        console.log(project)
        if(project == "Duplo" || project == "Prana" || project == "Velia"){
        }else{
            let navHeight = document.querySelector("#nav").clientHeight
            window.scrollTo(this.offset(elementFrom).top,this.offset(elementTo).top-(navHeight+30))
        }

        project = project.toLowerCase()
        const select = document.querySelector("select[name=project]")

        if(PROJECTS_CRM[project]?.allowLeadCreation){
            console.log(project)
            select.value = project
        } else {
            select.selectedIndex = 0
        }
        select.dispatchEvent(new Event("change",{bubbles: true}))
        window.dataLayer && window.dataLayer.push({
            event: "gaEvent",
            eventCategory: "Clic banner proyecto",
            eventLabel: project,
            eventAction: "Click"
        })
        // this.setState({toggle:false})
    }
    offset(e){
        let element = document.querySelector(e)
        console.log(element)
        if(element){
            try { 
                let top = element.getBoundingClientRect().top + window.scrollY 
                let left = element.getBoundingClientRect().left
                return {top:top,left: left}
            }catch(err){
                console.log(err.message)
                return "doesnt exist"
            }
        }else{
            console.log("doesn't exist")
        }
    }
    beforeChange=(currentSlide,nextSlide)=>{
        console.log("before change",currentSlide,nextSlide)
        try {
           
        } catch (error) {
            console.log(error)
        }
    }
    render() {
        return (
            <main className="inicio">
                {/* {
                    <Popup></Popup>
                } */}
                <section className="content_slider" id="slider-main">
                    <Slider {...this.state.settings} beforeChange={this.beforeChange} ref={this.slider}>
                        {
                            this.state.sliderMain.map((e,index)=>{
                                return(
                                    <div key={"img-"+index} className={`content_img_slider content_img_slider-${index} ${e.video && !window.isMobile() ? "video" : ""}`}>
                                    {e.img &&
                                        <React.Fragment>
                                            <div className="content-info-slide">
                                                <section>
                                                    <span className="distric c-white">{e.district.toUpperCase()}</span>
                                                    <span className="project c-white">{e.project}</span>
                                                    <span className="c-white mb-1 mt-1 address" dangerouslySetInnerHTML={{__html: `<img src="${require("../../assets/images/icons/locationMark.svg").default}"></img><div>${e.address}, ${e.district}</div>`}}></span>
                                                    <div className={`btn-radio c-white b-${e.project || e.name}`} onClick={()=>{this.moveTo("#slider-main","#formulario",e.project || e.name)}}>
                                                        <span target="_blank" className="c-white">{e.about}</span>
                                                        {e.about === "Vendido" ? null : <img src={require("../../assets/images/icons/signal-right.svg").default}></img> }
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className={`c-white phases ${e.phases[0].name === "" ? "" : "uppercase"}`}>
                                                        {e.singlePhaseFallbackText && <div>{e.singlePhaseFallbackText}<span>&nbsp;|&nbsp;</span></div>}
                                                        {e.phases.map((phase, i) =>
                                                            <div><b>{phase.name}</b> <span>{phase.stage}</span>{i !== e.phases.length-1 && !window.isMobile() && <span>&nbsp;|&nbsp;</span>}</div>
                                                        )}
                                                    </div>
                                                    <div className="prices">
                                                        {e.bedroomFeePrices?.map(([bedroom, fee]) =>
                                                            <div className="c-white">
                                                                <div className='ctx-dorms'>
                                                                    <p className='dorms'><b>{bedroom}.</b> desde</p>
                                                                </div>
                                                                <p><b><sup>S/</sup>{fee}</b></p>
                                                                <p>al mes*</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </section>
                                            </div>
                                            <picture className="expand-img">
                                                <source media="(min-width: 600px)" srcSet={e.img}></source>
                                                <img alt={"edificio-"+e.project} className="img" src={e.imgMovil}></img>
                                            </picture>
                                        </React.Fragment>
                                    }
                                    {/* <MediaQuery query="(min-width: 1400px)"> */}
                                        {e.video && !window.isMobile() &&
                                            <React.Fragment>
                                                <div className={`speaker ${this.state.videoMuted ? "muted" : "unmuted"}`} onClick={e=>this.setState({videoMuted: !this.state.videoMuted})}>
                                                </div>
                                                <video {...this.state.videoMuted && {muted: true}} autoPlay={true} loop={false} onLoadStart={e=>this.slider.current.slickPause()} onEnded={e=>{
                                                    this.activateAutoplay();
                                                    this.slider.current.slickPlay()
                                                    this.slider.current.slickNext()
                                                }}>
                                                    <source src={e.video} type="video/mp4"></source>
                                                </video>
                                            </React.Fragment>
                                        }
                                    {/* </MediaQuery> */}
                                    </div>
                                )
                            })
                        }
                    </Slider>
                    <div className="content-select-slider">
                    {
                        this.state.sliderMain.map((e,index)=>
                            <div key={"selectores-"+index} className={`item ${this.state.activeSlider == index ? "active" : ""}`}
                                onClick={(e)=>{this.changeSlider(e,index)}}>
                                <div className={`line ${e.project || e.name}`}></div>
                                <span className="district">{e.district?.toUpperCase() || e.headTitle}</span>
                                <span className="project">{e.project || e.name}</span>
                            </div>
                        )
                    }
                    </div>
                </section>
                <section className="project" id="projects">
                    <h2 className="title title-project">Nuestros <strong>Proyectos</strong></h2>
                    <Filter cardFilter={this.props.data.cardsFilter}></Filter>
                </section>
                <section className="information">
                    <h2 className="title"><strong></strong></h2>
                    <div className="banner">
                        <div className="circle circle-1"></div>
                        <div className="circle circle-2"></div>
                        <img className="doglover" id="doglover" src={require("../../assets/images/icons/persona.png")}></img>
                        <img className="phone" id="phone" src={require("../../assets/images/icons/phone.png")}></img>
                        <h3 className="title">Cotiza el depa de tus sueños</h3>
                        <span className="sub-title">La facilidad de separar tu depa desde donde estés.</span>
                        <a href="https://xn--eldepadetussueos-kub.pe/" className="btn-radio c-white">
                                Saber más
                        </a>
                        <img className="laptop" id="laptop" src={require("../../assets/images/icons/laptop.png")}></img>
                    </div>
                    {/* <div className="buttons">
                        <div className="info_button b-primary">
                            <div className="comentario"></div>
                            <span className="title-button c-white">Preguntas frecuentes</span>
                            <a target="_blank" href="https://www.cosapiinmobiliaria.com.pe/preguntas-frecuentes" className="button"><div className="add b-primary"></div></a>
                        </div>
                        <div className="info_button b-primary">
                            <div className="users"></div>
                            <span className="title-button c-white">Propietarios</span>
                            <a href="https://www.cosapiinmobiliaria.com.pe/atencion-al-propietario" className="button"><div className="add b-primary"></div></a>
                        </div>
                        <div className="info_button whatsapp b-yellow">
                            <div className="content-info-hidden">
                                <img className="hand" src={require("../../assets/images/icons/hand.png")}></img>
                                <img className="whatsapp" src={require("../../assets/images/icons/whatsapp.png")}></img>
                                <span className="title-button c-white">Contact center Whatsapp</span>
                                <span className="sub-title-button c-white">Comunícate con nosotros</span>
                                <a target="_blank" href="https://wa.link/4hxmi2" className="button"><div className="add b-yellow"></div></a>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className='banner-family'>
                        <picture>
                            <source media="(min-width: 900px)" srcSet={require("../../assets/images/icons/banner-web.png")}></source>
                            <img className='' src={require('../../assets/images/icons/banner-mobile.png')} ></img>
                        </picture>
                        <p className='paragraph'>
                        ¡Refiérenos a tu familia y amigos <br/><strong>y gana una Gifcard!</strong>
                        </p>
                        <a target="_blank" href="/referidos" className="button"><div className="add b-primary"></div></a>
                    </div> */}
                </section>
                <section className="formulario container" id="formulario">
                    <h2 className="title">¡Estás a un paso de alcanzar tu sueño!<br/><strong>Agenda una asesoría personalizada</strong></h2>
                    <Formulario modal={this.activeModal}></Formulario>
                </section>
            </main>
        )
    }
}