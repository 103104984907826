import React, { Component } from 'react'
import "./filter.scss"
import Slider from "react-slick";
import Range from '../../component/range/range';
import MediaQuery from 'react-responsive';
import { dataInicio } from '../../data/inicio'
import SliderOptions from '../sliderOptions/sliderOptions';
import { PROJECTS_CRM } from '../../data/projects';
// const fs = require("fs");
export default class Filter extends Component {
    constructor(props){
        super(props)
        this.activeIcon = this.activeIcon.bind(this)
        this.afterChange = this.afterChange.bind(this)
        this.changeDorms = this.changeDorms.bind(this)
        this.openFilter = this.openFilter.bind(this)
        this.returnFasesActive = this.returnFasesActive.bind(this)
        this.getData = this.getData.bind(this)
        this.selectOnChange = this.selectOnChange.bind(this)
        this.updateDistric = this.updateDistric.bind(this)
        this.select = React.createRef();
        this.state={
            filter:false,
            cards:dataInicio.cardsFilter,
            projects:[],
            numDorms:[],
            distric:"all",
            fase:'',
            districReset:"selected",
            updateDistric:this.updateDistric,
            changeDorms:this.changeDorms,
            updateFases:this.updateFases,
            settingsCards:{
                dots: false,
                arrows:false,
                infinite: false,
                centerMode: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true,
                afterChange:this.afterChange
            },
            settingsRange:{
                min:0,
                max:3,
                step:1,
                getData:this.getData
            }
        }
    }
    activeIcon =(e)=>{
        return `line-${this.state.projects[0]} line-${this.state.projects[this.state.projects.length-1]}`
    }
    getData(data){
        console.log(data)
        this.setState({projects:data})
        let projects = dataInicio.cardsFilter;
        let projectsFilter = projects.filter((item)=>{
            if((item.district == this.state.distric || item.all == this.state.distric)
            && this.returnDormsActive(this.state.numDorms,item.dorms)
            && this.returnFasesActive(item.fase,data)){
                return item
            } 
        })
        this.setState({cards:projectsFilter});
    }
    afterChange(e){
        this.setState({activeSlider:e})
    }
    changeDorms(e,num){
        let projects = dataInicio.cardsFilter;
        let numDorms = [];
        numDorms[0] = num; 
        if(this.state.numDorms.includes(num)){
            this.setState({numDorms:[]});
            let projectsFilter = projects.filter((item)=>{
                if((item.district == this.state.distric || item.all == this.state.distric)
                && this.returnDormsActive([],item.dorms)
                && this.updateFases(item,this.state.fase.length > 0 ? this.state.fase : 'all')){
                    return item
                } 
            })
            this.setState({cards:projectsFilter});


        }else{
            let projectsFilter = projects.filter((item)=>{
                if((item.district == this.state.distric || item.all == this.state.distric)
                && this.returnDormsActive(numDorms,item.dorms)
                && this.updateFases(item,this.state.fase.length > 0 ? this.state.fase : 'all')){
                    return item
                } 
            })
            this.setState({numDorms:numDorms,cards:projectsFilter});

        }

    }
    
    selectOnChange(e){
        
        let projects = dataInicio.cardsFilter;
        this.setState({distric:e.target.value})
        let projectsFilter = projects.filter((item)=>{
            if((item.district == e.target.value || item.all == e.target.value)
            && this.returnDormsActive(this.state.numDorms,item.dorms)
            && this.updateFases(item,this.state.fase.length > 0 ? this.state.fase : 'all')
            ){
                return item
            } 
        })
        this.setState({cards:projectsFilter})
    }
    returnFasesActive(fase,data){
        console.log({data})
        if(data.length == 0){
            return true
        }else{
            let dataNew = data.map((e)=>{
                if(e == 0){return e = "Lanzamiento"}
                if(e == 1){return e = "Preventa"}
                if(e == 2){return e = "Construcción"}
                if(e == 3){return e = "Entrega Inmediata"}
            })
            if(dataNew.includes(fase)){   
                return true
            }else{
                return false
            }
        }
    }
    returnDormsActive(dorms,itemsDorms){
        let value = [];
        dorms.map((e)=>{
            if(itemsDorms.includes(e) == true){
                value.push(true)
            }else{
                value.push(false)
            }
        })
        value.includes(false) == false ? value = true : value = false; 
        return value
    }
    openFilter(){
        this.setState({filter:!this.state.filter})
    }
    updateDistric(distric){
        console.log(distric)
        let projects = dataInicio.cardsFilter;
        this.setState({distric:"all"})
        let projectsFilter = projects.filter((item)=>{
            if((item.all == "all")
            && this.returnDormsActive(this.state.numDorms,item.dorms)
            && this.updateFases(item,this.state.fase)
            ){
                return item
            } 
        })
        this.setState({cards:projectsFilter})
    }
    updateFases=(item,fase)=>{
        console.log("cards",this.state.cards)
        if(fase == 'all'){
            return true
        }else{
            if(item.fases.includes(fase)){return true}
        }
    }
    changeFases=(fase)=>{
        
        if(this.state.fase == fase){
            this.setState({fase:''})
            let projects = dataInicio.cardsFilter;
            let projectsFilter = projects.filter((item)=>{
                if((item.district == this.state.distric || item.all == this.state.distric) && this.returnDormsActive(this.state.numDorms,item.dorms) && this.updateFases(item,'')){
                    return item
                } 
            })
            
            this.setState({cards:projectsFilter})
        }else{

            this.setState({fase:fase})
            let projects = dataInicio.cardsFilter;
            let projectsFilter = projects.filter((item)=>{
                if((item.district == this.state.distric || item.all == this.state.distric) &&
                    this.returnDormsActive(this.state.numDorms,item.dorms) && this.updateFases(item,fase)){
                    return item
                } 
            })
    
            this.setState({cards:projectsFilter})
        }
    }
    componentDidMount(){
        // console.log(dataInicio.cardsFilter)
    }
    render() {
        const dorms = [1,2,3];
        const districts = [... new Set(dataInicio.cardsFilter.map(c=>c.district))]
        
        return (
            <div className="container">
                <div className={`filter ${this.state.filter ? "active" : ""}`}>
                    <h2 className="title-filter-box">Filtros</h2>
                    <div className="icon-close" onClick={(e)=>{this.openFilter(e)}}></div>
                    <div className="selector">
                        <span className="title-filter">¿En qué distrito <strong>estás buscando?</strong></span>
                        <select ref={this.select} name="distritos" onChange={(e)=>{this.selectOnChange(e)}}>
                            <option value="all">Todos</option>
                            {districts.map(district=>
                            <option key={district} value={district}>{district}</option>
                            )}
                        </select>
                    </div>
                    <div className="line-v"></div>
                    <div className="select-button">
                        <span className="title-filter f-weight">N° de dormitorios</span>
                        <div className="content-ball">
                            {
                                dorms.map((num,index)=>{
                                    return(
                                        <div key={"dorms-"+index} className={`ball ${this.state.numDorms.includes(index+1) ? "active" : ""}`} onClick={e=>this.changeDorms(e,num)}>{num}</div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="line-v"></div>
                    <span className="title-filter movil">Etapa</span>
                    <div className="project-fase">
                        <Range setting={this.state.settingsRange}>
                            <div className={`line_range ${this.activeIcon(this.state.projects)}`}>
                                <div className="line_active_show"></div>                    
                            </div>
                            <div className={`cicle-gray circle-shadow-0 ${this.state.fase.includes('Lanzamiento') ? "active" : ""}`}>
                                <div className="circle-shadow"></div>
                                <div className="item-shadow" onClick={()=>{this.changeFases('Lanzamiento')}}>
                                    <div className="img-shadow nave"></div>
                                    <span className="title-shadow">Lanzamiento</span>
                                </div>
                            </div>
                            <div className={`cicle-gray circle-shadow-1 ${this.state.fase.includes('Preventa') ? "active" : ""}`}>
                                <div className="circle-shadow"></div>
                                <div className="item-shadow" onClick={()=>{this.changeFases('Preventa')}}>
                                    <div className="img-shadow preventa"></div>
                                    <span className="title-shadow">Preventa</span>
                                </div>
                            </div>
                            <div className={`cicle-gray circle-shadow-2 ${this.state.fase.includes('Construcción') ? "active" : ""}`}>
                                <div className="circle-shadow"></div>
                                <div className="item-shadow" onClick={()=>{this.changeFases('Construcción')}}>
                                    <div className="img-shadow construccion"></div>
                                    <span className="title-shadow">Construcción</span>
                                </div>
                            </div>
                            <div className={`cicle-gray circle-shadow-3 ${this.state.fase.includes('Entrega Inmediata') ? "active" : ""}`}>
                                <div className="circle-shadow"></div>
                                <div className="item-shadow" onClick={()=>{this.changeFases('Entrega Inmediata')}}>
                                    <div className="img-shadow key"></div>
                                    <span className="title-shadow">Entrega inmediata</span>
                                </div>
                            </div>
                        </Range>
                    </div>
                    <div className="btn-aplicar">
                        <div className="btn-radio-aplicar" onClick={(e)=>{this.openFilter(e)}}>Aplicar</div>
                    </div>     
                </div>
                <div className="btn-filter">
                    <button className="btn-filtros" onClick={(e)=>{this.openFilter(e)}}><span className="icon-filter"></span>Filtros</button>
                    <SliderOptions options={this.state}></SliderOptions>
                </div>
                <div className="projects">
                    <MediaQuery query="(min-width: 600px)">
                        {   
                        this.state.cards.length == 0 ? <Message></Message> : 
                            this.state.cards.map((e,index)=>{
                                return(
                                    <SliderMain key={"card-"+index} option={e} index={e.index}></SliderMain>
                                )
                            })
                        }
                    </MediaQuery>
                    <MediaQuery query="(max-width: 600px)">
                        {this.state.cards.length == 0 ? <Message></Message> : null}
                        {this.state.cards.length == 1 ? <SliderMain key={"card-"+0} option={this.state.cards[0]} index={"index"-0}></SliderMain> : null}
                        {this.state.cards.length > 1 ? <Slider {...this.state.settingsCards}>
                            {   
                                this.state.cards.map((e,index)=>{
                                    return(
                                        <SliderMain key={"cardSlider-"+index} option={e} index={e.index}></SliderMain>
                                    )
                                })
                            }
                            </Slider> : null
                        }
                    </MediaQuery>
                </div>
            </div>

        )
    }
}
function Valuation(props){
    return(<div className="valuation" dangerouslySetInnerHTML={{__html: props.valuation}}></div>)
}
function Message(props){
    return(<div className="evaluation">Lo sentimos, pero no contamos con proyectos que contengan estas características.</div>)
}
class SliderMain extends Component{
    constructor(props){
        super(props)
        this.offset = this.offset.bind(this)
        this.moveTo = this.moveTo.bind(this)
        this.counter = this.counter.bind(this)
        this.state = {
            num:this.props.option.views.desde,
        }
    }
    moveTo(elementFrom,elementTo,project,element){
        let navHeight = document.querySelector("#nav").clientHeight
        window.scrollTo(this.offset(elementFrom).top,this.offset(elementTo).top-(navHeight+30))
        project = project.toLowerCase()
        const select = document.querySelector("select[name=project]")

        if(PROJECTS_CRM[project]?.allowLeadCreation){
            console.log(project)
            select.value = project
        } else {
            select.selectedIndex = 0
        }
        select.dispatchEvent(new Event("change",{bubbles: true}))
        // this.setState({toggle:false})
    }
    offset(e){
        let element = document.querySelector(e)
        console.log(element)
        if(element){
            try { 
                let top = element.getBoundingClientRect().top + window.scrollY 
                let left = element.getBoundingClientRect().left
                return {top:top,left: left}
            }catch(err){
                console.log(err.message)
                return "dosnt exist"
            }
        }else{
            console.log("doesn't exist")
        }
    }
    counter(num,step){
        // fs.writeFile('./credentials.json', getAuthJSON, (err) => {
        //     if (err) throw err;
        //     console.log('Data written to file');
        // });
        if(window.innerWidth > 800){

            var cont = num.desde - 60;
            let countTime = setInterval(() => {
                if(cont >= num.desde){
                    clearInterval(countTime)
                }else{
                    cont = cont + step
                    this.setState({num:cont})
                }
            },60)
        }
    }
    prevent=(event,link)=>{
        console.log(link)
        if(link.length > 0){
            window.location.href = link
        }else{
            event.preventDefault()
        }
    }

    render(){
        return(
            <div key={"card-item"+this.props.option} className={`card-item ${this.props.option.project}`} 
                onMouseEnter ={()=>{this.counter(this.props.option.views,5)}} onClick={(event)=>{this.prevent(event,this.props.option.link)}} 
            >
                <div className={`item-brand brand-${this.props.option.project}`}>
                </div>
                
                <div className="content-render">
                    <img className="render" src={this.props.option.imgProject}></img>
                    <div className={`fase-content`}>
                        <div className={`fase b-${this.props.option.project}`}>
                            {this.props.option.fase}
                        </div>
                    </div>
                </div>
                <div className="background-shadow">
                
                    <div className="details">
                        <div className="content-detail">
                            <div className={`detail-brand ${this.props.option.imgBrand}`}></div>
                            {
                                this.props.option.link.length > 0 ? 
                                <a className="button" href={this.props.option.link}>
                                    <div className={`add b-${this.props.option.project}`}></div>
                                </a> : 
                                <div className="button" onClick={()=>{this.moveTo(".projects","#formulario",this.props.option.project)}}>
                                    <div className={`add b-${this.props.option.project}`}></div>
                                </div> 
                            }
                        </div>
                        <div className="content-detail">
                            <div className={`district b-${this.props.option.project}`}>{this.props.option.district}</div>
                            <div className={`dorms`}>
                                <div className="icon bed"></div>
                                {this.props.option.dormsShow}
                            </div>
                        </div>
                        <div className="content-detail flex-col">
                            <div className={`content-place`}>
                                <div className={"icon place b-"+this.props.option.project}></div>
                                <span className="title-place" dangerouslySetInnerHTML={{__html: `${this.props.option.address}, ${this.props.option.district}`}}></span>
                            </div>
                            {
                                this.props.option.valuation.length > 0  ?  <Valuation valuation={this.props.option.valuation}></Valuation> : null
                            }
                        </div>
                        <div className='separator'></div>
                        {
                            this.props.option.views == 0 ? null : 
                        <div className="content-detail bottom link-view">
                            <div className={`views`}>
                                <div className="icon view"></div>
                                <span className="title-view"><span className="cant">{this.state.num}</span> visitas</span>
                            </div>
                            {   this.props.option.tendency.length == 0 ? null :
                            <div className={`tendency`}>
                                <div className="icon signal"></div>
                                <span className="title-tendency">{this.props.option.tendency}</span>
                            </div>
                            }
                        </div>
                        }
                        <div className="content-detail bottom link">
                             <a className={`link b-${this.props.option.project}`} href={this.props.option.link}>{this.props.option.button}</a>
                            
                            
                        </div>
                        {/* {
                            this.props.option.views == 0 ? null : 
                        <div className="content-detail bottom link-view">
                            <div className={`views`}>
                                Desde {
                                    this.props.option.price_from
                                }
                            </div>
                        
                        </div>
                        }
                        <div className="content-detail bottom link">
                             <a className={`link b-${this.props.option.project}`} href={this.props.option.link}>{this.props.option.button}</a>
                            
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}