import axios from "axios";

export class MailService {
    constructor() {
        this.mailgun = axios.create({
            baseURL: "https://cors.formulaperu.com/https://api.mailgun.net/v3/mg.formulaperu.com/",
            auth: {
                username: 'api',
                password: 'key-2f8526fda8b88fce4bc2fd3f1858cca7'
            }
        })
        this.payload = new FormData()
    }

    from(from) {
        this.payload.set("from", from)
        return this
    }

    to(to) {
        this.payload.set("to", to)
        return this
    }

    subject(subject) {
        this.payload.set("subject", subject)
        return this
    }

    html(html) {
        this.payload.set("html", html)
        return this
    }

    attachment(attachment) {
        this.payload.set("attachment", attachment)
        return this
    }

    async send() {
        return await this.mailgun.post("/messages", this.payload)
    }
}