import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import './inversion.scss'
class Inversion extends Component {
    constructor(props){
        super(props)
        console.log(props)
        this.afterChange = this.afterChange.bind(this)
        this.state = {
            settings:{
                dots: false,
                arrows:false,
                infinite: false,
                speed: 500,
                autoplay: false,
                autoplaySpeed: 3000,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true,
                afterChange:this.afterChange
            },
        }
    }
    afterChange(e,position){
        // this.slider.slickGoTo(position)
    }
    render() {
    return (
        <main className="inversion">
            <div className='mini-ball mini-ball-2 absolute'></div>
            <div className='mini-ball mini-ball-1 absolute'>
            </div>
            <div className='mini-ball mini-ball-9 absolute'></div>
            <div className='mini-ball mini-ball-8 absolute'></div>
            <div className='d-flex container flex-col justify-center items-center'>
                <h1 className="title c-secondary gotham-bold text-center">Pasos para adquirir tu depa </h1>
                <p className='paragraph text-center text-balance'>
                    Es importante que todo gran paso se realice en conjunto con las personas que tomarán la decisión de adquirir el departamento. Es decir, si tienes pareja, asegúrate de analizar juntos lo siguiente.
                </p>
            </div>
            <div className='container'>
               
                <div className='slider-steps'>
                    <div className='bar'>
                    </div>
                    {
                        this.props.value.map((obj,index)=>{
                            return(
                                <div className={`content-step `}>
                                    <div className={`step ${index % 2 === 0 ? "v2" : "v1"}`}>   
                                        <div className="content-imagen">
                                            <img className="imagen" src={obj.img}/>            
                                        </div>
                                        <div className='content-text'>
                                            <div className='d-flex flex-col ctx relative'>
                                                <div className={`line b-primary line-${index}`}>
                                                    <div className='ball-line'>
                                                    </div>
                                                </div>
                                                <div className="index gotham-bold">{"0"+(index+1)}</div>
                                                <span className='title-step c-secondary gotham-bold'>{obj.title}</span>
                                                <p className="paragraph c-secondary">
                                                    {obj.paragraph}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </main>
    );
  }
}
const  MapStateProps = (state) =>{
    return(
        {
            value:state.inversionStore.data
        }
    )
}

export default connect(MapStateProps,{})(Inversion)