import React, { Component } from 'react'
import "./machines.scss"
import $ from 'jquery'
export default class Electrodomesticos extends Component {
    constructor(props){
        super(props)
        this.activeButtonElectrodomestic = this.activeButtonElectrodomestic.bind(this)
        this.activeButtonTecnology = this.activeButtonTecnology.bind(this)
        this.state = {
            electrodomestic:1,
            tecnology:1,
            rel:""
        }
    }
    componentDidMount(){
        $("#area").text("Aliados")
        $(".menu-aliados").addClass("active");
    }
    activeButtonElectrodomestic(e,item){
        this.setState({rel:""})
        this.setState({electrodomestic:item})
        let timing = setInterval(() => {
            this.setState({rel:"?rel=0"})
            clearInterval(timing)
        }, 100);
    }
    activeButtonTecnology(e,item){
        this.setState({rel:""})
        this.setState({tecnology:item})
        let timing = setInterval(() => {
            this.setState({rel:"?rel=0"})
            clearInterval(timing)
        }, 100);
    }
    render() {
        // const muvin = require("../../assets/images/muvin_logo.png")
        return (
            <div className="machine">
                <div className="landing-header">
                        <a href="/" className="d-block brand-muvin">
                            {/* <img src={muvin}></img> */}
                        </a>
                        <a href="https://gerpal.pe/" target="_blank" className="d-block brand-gerpal"><img src={require('../../assets/images/icons/brand-gerpal.png')}></img></a>
                        <a href="https://www.cosapiinmobiliaria.com.pe/" target="_blank" className="d-block brand-cosapi"><img src={require('../../assets/images/icons/brand-cosapi-mas.png')}></img></a>
                </div>
                <div className="content-electrodomestico">
                    <div className="content">
                        <div className="d-flex">
                            <div className="content-imagen">
                                <img className="img" src={require("../../assets/images/icons/machine/bosch.png")}></img>
                                <a href="https://www.bosch-home.pe/" className="btn-site"><div className="global"></div>Visitar sitio web</a>
                            </div>
                            <p className="paragraph">
                                Durante generaciones, los electrodomésticos Bosch nos han hecho la vida más sencilla: como asistentes diarios, han respondido siempre a las demandas más exigentes. Se han encargado durante un siglo y medio de innumerables tareas tediosas en incontables hogares, sencillamente para ahorrarnos tiempo y mejorar nuestra calidad de vida. 
                            </p>
                            <p className="paragraph">
                                Ahora, toda la calidad de los electrodomésticos <span className="bold">Bosch viene incluido en tu departamento.</span> Gracias a esta alianza podrás disfrutar de horno, encimera y campana de la marca Bosch.
                            </p>
                            <div className="content-button">
                                <div className={`button`}>
                                    <img className="img-button" src={require("../../assets/images/icons/aliados/horno.png")}></img>
                                    <span className="title-button">HORNO</span>
                                </div>
                                <div className={`button`} >
                                    <img className="img-button" src={require("../../assets/images/icons/aliados/encimera.png")}></img>
                                    <span className="title-button">ENCIMERA</span>
                                </div>
                                <div className={`button campana`} >
                                    <img className="img-button" src={require("../../assets/images/icons/aliados/campana.png")}></img>
                                    <span className="title-button">CAMPANA</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="content-video">
                                <iframe className={"active"} height="100%" width="100%" src="https://www.youtube.com/embed/COYdpuvkSUM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div className="content-button-signal">
                                <div className={`signal ${this.state.electrodomestic == 1 ? "active":""}`}></div>
                                <div className={`signal ${this.state.electrodomestic == 2 ? "active":""}`}></div>
                                <div className={`signal ${this.state.electrodomestic == 3 ? "active":""}`}></div>
                            </div>
                        </div>
                    </div>
                    <div className="background-pink"></div>
                </div>
                <div className="content-tecnologia">
                    <div className="background-sky"></div>
                    <div className="content">
                        <div>
                            <div className="content-video">
                                <iframe className="active" width="100%"  height="100%" src="https://www.youtube.com/embed/m8TWpTb3YXA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div className="content-button-signal">
                                <div className={`signal ${this.state.tecnology == 1 ? "active":""}`}></div>
                                <div className={`signal ${this.state.tecnology == 2 ? "active":""}`}></div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="content-imagen">
                                <img className="img" src={require("../../assets/images/icons/logo-smart-house.jpg")}></img>
                                <a href="http://www.smarthouseperu.com/" className="btn-site"><div className="global"></div>Visitar sitio web</a>
                            </div>
                            <p className="paragraph">
                            Una empresa dedicada a brindar soluciones tecnológicas para potenciar el estilo de vida, con el fin de hacer vivir a las personas la verdadera
                            experiencia del confort total dentro de su hogar.
                            </p>
                            <p className="paragraph">
                            La tecnología llegó a tu departamento,<span className="bold"> vuelve tu hogar en un espacio smart.</span> Gracias a SmartHouse ahora tu depa viene con un Echo dot 3 e interruptores inteligentes.
                            </p>
                            <div className="content-button">
                                <div className={`button dot `} >
                                    <img className="img-button" src={require("../../assets/images/icons/aliados/echo-dot.png")}></img>
                                    <span className="title-button">ECHO DOT 3</span>
                                </div>
                                <div className={`button`} >
                                    <img className="img-button" src={require("../../assets/images/icons/aliados/interruptor.png")}></img>
                                    <span className="title-button">INTERRUPTOR</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span className='title-bank'>Bancos aliados</span>
                <div className='banks'>
                    <div className='bank-item'>
                        <div className='content-img'>
                            <img className='' src={require('../../assets/images/icons/bcp.png')} ></img>
                        </div>
                        <p className='paragraph'>Banco de Crédito del Perú es el banco más grande y el proveedor líder de servicios financieros integrados en el Perú.</p>
                        <a href="https://www.viabcp.com/" className="btn-site bcp"><div className="global"></div>Visitar sitio web</a>
                    </div>
                    <div className='bank-item'>
                        <div className='content-img'>
                            <img className='' src={require('../../assets/images/icons/banbif.svg').default} ></img>
                        </div>
                        <p className='paragraph'>BanBif con más de 30 años y consolidado como uno de los cinco primeros bancos en el Perú.</p>
                        <a href="https://www.banbif.com.pe/" className="btn-site banbif"><div className="global"></div>Visitar sitio web</a>
                    </div>
                </div>
                    <p className='paragraph paragraph-bank'>* Consultar con los asesores de Cosapi Inmobiliaria en qué proyectos aplican nuestras alianzas.</p>
            </div>
        )
    }
}
