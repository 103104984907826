import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './AtencionAlCliente.scss'

export default class AtencionAlCliente extends Component {
    componentDidMount(){
        window.scrollTo(0,0)
    }
    render(){
        return (
            <main className="AtencionAlCliente">
                <div className="mini-ball mini-ball-1"></div>
                <div className="mini-ball mini-ball-2"></div>
                <div className="contain">
                    <h1 className="gotham-bold title c-secondary">Atención al cliente</h1>
                    <p className="paragraph c-secondary gotham-book">
                        En Cosapi Inmobiliaria estamos felices de ayudarte en cualquier duda, solicitud y
                        requerimiento. Para una mejor atención, por favor selecciona si estás buscando un
                        departamento o si ya eres un propietario.
                    </p>
                    <div className="options d-flex">
                        <div className="item">
                            <div className="img">
                                <img src={require("../../assets/images/atencion/buscando-depa.png")}></img>
                            </div>
                            <Link to={"/preguntas-frecuentes"} className="btn b-primary gotham-bold">Estoy buscando un depa</Link>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={require("../../assets/images/atencion/propietario.png")}></img>
                            </div>
                            <Link to={"/atencion-al-propietario"} className="btn b-primary gotham-bold">Soy propietario</Link>
                        </div>
                    </div>
                </div>
            </main>
        )}
}