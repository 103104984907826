import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value:false
}
export const modalSlice = createSlice({
    name:"modal",
    initialState,
    reducers:{
        active: (state)=>{
            state.value = true
        },
        close: (state)=>{
            state.value = false
        }
    }
})

export const { active, close } = modalSlice.actions
export default modalSlice.reducer 