import React, { Component } from 'react'
import "./popup.scss"
export default class Popup extends Component {
    constructor(props){
        super(props)
        this.state = {
            
            sleepPopup: false,
            image: require("../../assets/images/popup/popup.png"),
            link: "https://summerdays.cosapiinmobiliaria.com.pe/",
        };
    }
    componentDidMount(){
       
    }
    close(){
        document.querySelector(".Popup").classList.add("opacity")
    }
    render() {
        return (
        <div className="Popup">
            <div className="content-popup">
                <a href={this.state.link} className="link-popup" target={"_blank"}></a>
                <img className="img-popup" src={this.state.image}></img>
                <div className="close"style={{zIndex:2}} onClick={()=>{this.close()}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.971 47.971"><g><path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"></path></g></svg>
                </div>
            </div>
        </div>
        )
    }
}
