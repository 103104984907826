import React, { Component } from 'react';
import './loader.scss'
export default class Loader extends Component {
  render() {
    return (
        <div className="loader">
            <div className='spinning'>
                <img className='' src={require('../../assets/images/icons/loader.svg').default} ></img>
            </div>
        </div>
    );
  }
}
