export const dataConocenos = {
    cards:[
        {img:require("../assets/images/render/render-nesta.jpg"),project:"nesta",text:"Jesús María",link:"https://nestacosapi.grupolar.pe/avance-de-obra/piros"},
        {img:require("../assets/images/render/render-eqipe.jpg"),project:"epiqe",text:"Surco",link:"https://epiqe.cosapiinmobiliaria.com.pe/avance-obra"},
        {img:require("../assets/images/render/render-sente.jpg"),project:"sente",text:"Breña",link:"https://sentecosapi.grupolar.pe/avance-de-obra/"},

        {img:require("../assets/images/render/render-duplo.jpg"),project:"duplo",text:"Breña",link:""},
        {img:require("../assets/images/render/render-prana.jpg"),project:"prana",text:"Pueblo Libre",link:""},
        {img:require("../assets/images/render/render-velia.jpg"),project:"velia",text:"Lince",link:""},
    ],
    slider:[
        {img:require("../assets/images/conocenos/slider/estadio.webp"),title:"Estadio Nacional"},
        {img:require("../assets/images/conocenos/slider/banco-de-la-nacion.webp"),title:"Banco de la Nación"},
        {img:require("../assets/images/conocenos/slider/interbank.webp"),title:"Torre Interbank"},
    ],
    features:[
        "• La Línea Dos del Metro de Lima (actualmente en ejecución).",
        "• La nueva sede institucional del Banco de la Nación",
        "• Remodelación del Estadio Nacional.",
        "• La sede principal del Banco Interbank.",
        "• El Centro Comercial Jockey Plaza.",
        "• Ampliación del Aeropuerto Jorge Chávez",
        "• La planta concentradora de la Mina Antamina",
        "• Tercera etapa de proyecto Shougang.",
        "• Planta de Fraccionamiento Pluspetrol.",
        "• Construcción del Colector en estación Pirámide del Sol."
    ],
    valores:[
        {icon:"confidence",title:"Espíritu de Equipo",paragraph:"Colaborar, cooperar y conjugar esfuerzos con un grupo de personas, a fin de alcanzar objetivos comunes enriqueciendo la experiencia propia con la de otros miembros del grupo, y produciendo un resultado mayor que la suma de los esfuerzos individuales."},
        {icon:"icon-hand",title:"Integridad",paragraph:"Coherencia entre la palabra y la acción en un sentido de rectitud, probidad y respeto."},
        {icon:"light",title:"Innovación",paragraph:"Disposición de modificar las formas existentes de hacer las cosas asumiendo con responsabilidad el riesgo de llevarlas a la práctica,buscando optener ventajas competitivas, la eficiencia de nuestros procesos y el éxito  de nuestros resultados."},
        {icon:"flat",title:"Liderazgo",paragraph:"Capacidad de orientar el esfuerzo de los grupos humanos en una dirección deseada, promoviendo una visión compartida, estructurándolos, dirigiéndolos, generando oportunidades de crecimiento, inspirando valores de acción y anticipando escenarios de desarrollo."},
    ],
    certificados:[
        
    ]
} 