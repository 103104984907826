import { includes } from 'lodash'
import React, { Component, memo, useRef } from 'react'
import OwlCarousel from "react-owl-carousel2"
import Slider from "react-slick";
import MediaQuery from 'react-responsive'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import './conocenos.scss'
import { ref } from 'yup';


const SliderConocenos = memo(({slider = [], events = {}}) => {
    const sliderRef = useRef(null);
    const options ={
        items: 1,
        rewind: false,
        loop:true,
        nav:true,
        center:false,
        autoplay:true,
        smartSpeed:600,
        autoplaySpeed:2000,
        dots:true,
    }
    return (
        <OwlCarousel ref={sliderRef} options={options} events={events} className="owl-carousel">
        {
            slider.map((item)=>{
                return(
                    <div className='content-img'>
                        <img className='img' src={item.img}></img>
                        <div className='content-title-carousel c-white b-primary'>
                            {item?.title}
                        </div>
                    </div>
                )
            })
        }
        </OwlCarousel>
    )
})

export default class Conocenos extends Component {
    constructor(props){
        super(props)
        this.onDragged = this.onDragged.bind(this)
        this.onChanged = this.onChanged.bind(this)
        this.iconValores = this.iconValores.bind(this)
        this.selected = this.selected.bind(this)
        this.changeCertificates = this.changeCertificates.bind(this)
        this.owlRight = this.owlRight.bind(this)
        this.owlLeft = this.owlLeft.bind(this)
        this.changeSelect = this.changeSelect.bind(this)
        this.activeParagraphRespaldo = this.activeParagraphRespaldo.bind(this)
        this.activeParagraphResponsabilidad = this.activeParagraphResponsabilidad.bind(this)
        this.state = {
            reportDownload:"reporte_de_sostenibilidad_cosapi_2011",
            expanded:'',
            certificates:0,
            box:0,
            iconValores:0,
            activeParagraphRespaldo:false,
            activeParagraphResponsabilidad: false,
            positionSlider: 0,
            events:{
                onDragged:this.onDragged,
                onChanged:this.onChanged,
            },
            options:{
                items: 1,
                rewind: false,
                loop:true,
                nav:false,
                center:false,
                autoplay:true,
                smartSpeed:600,
                autoplaySpeed:2000,
                dots:true,
            },
            settingsCards:{
                dots: true,
                arrows:true,
                infinite: true,
                centerMode: false,
                autoplay: true,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 1,
                variableWidth: false,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            variableWidth: true,
                            slidesToScroll: 1
                        }
                    }
                ]

            },
            settingsOption:{
                dots: false,
                arrows:false,
                infinite: false,
                centerMode: false,
                speed: 300,
                slidesToScroll: 1,
                variableWidth: true,
            },
            pdfs:[
                {title:"Política de cumpliento",url:require("../../assets/images/pdfs/Política-de-cumplimiento.pdf")},
                {title:"Política de libre competencia",url:require("../../assets/images/pdfs/Política-de-libre-competencia.pdf")},
                {title:" Política de gestión de denuncias",url:require("../../assets/images/pdfs/Política-de-gestión-de-denuncias.pdf")},
                {title:"Política de protección de datos personales",url:require("../../assets/images/pdfs/Política-de-protección-de-datos-personales.pdf")},
                {title:"SPD Y PC",url:require("../../assets/images/pdfs/SPDyPC .pdf")},
            ]
        }
    }
    componentDidMount(){
        console.log("props",this.props.data.valores[1])
    }
    activeParagraphRespaldo(){
        this.setState({activeParagraphRespaldo:true})
    }
    activeParagraphResponsabilidad(){
        this.setState({activeParagraphResponsabilidad:true})
    }
    changeSelect = (event)=>{
        console.log(event.target.value)
        this.setState({ reportDownload: event.target.value });
    }

    handleChange = (panel) => (event, newExpanded) => {
        this.setState({expanded: newExpanded ? panel : false})
    }
    onChanged = (e)=>{
        let time = setInterval(() => {
            if(e.page.index > 0){
                this.setState({positionSlider:e.page.index})
            }
            clearInterval(time)
        }, 2000);
    }
    onDragged =(e)=>{
        let time = setInterval(() => {
            this.setState({positionSlider:this.sliderProject?.currentPosition})
            clearInterval(time)
        }, 500);
    }
    selected(e){
        this.setState({box:e})
    }
    iconValores(e){
        console.log("selected:",e)
        this.setState({iconValores:e})
    }
    changeCertificates(e){
        this.setState({certificates:e})
    }
    owlRight(){
        this.sliderProject.next()
    }
    owlLeft(){
        this.sliderProject.prev()
    }
    beforeChange = (oldIndex, newIndex) => {
        console.log(oldIndex, newIndex)
    }

    render(){
        return (
            <main className="conocenos">
                <div className="container content-info">
                    <div className='about'>
                        <h1 className="title c-secondary bold">¿Quiénes somos?</h1>
                        <p className="paragraph c-secondary">Cosapi Inmobiliaria es la empresa promotora <span className='gotham-bold'>inmobiliaria de Cosapi S.A.</span>
                            dedicada al desarrollo y construcción de bienes inmuebles,
                            principalmente destinados a la vivienda. </p>
                                <p className="paragraph c-secondary">
                        Tenemos el gran reto de desarrollar <span className='gotham-bold'> proyectos que mejoren la calidad
                        de vida</span> de la gente y que a su vez contribuyan activamente con la
                        generación de <span className='gotham-bold'>una mejor ciudad.</span> 
                        </p>
                        <p className="paragraph c-secondary">
                            Creemos que esto trasciende a nuestros proyectos, ya que <span className='gotham-bold'> estos deben
                            integrar las necesidades, deseos y aspiraciones de las personas</span> con los
                            requerimientos de una ciudad moderna.
                        </p>
                    </div>
                    <div className='about-img'>
                        <img className="desk" src={require("../../assets/images/conocenos/background-blur.png")}></img>
                    </div>
                </div>
                <div className='container counter '>
                    <img className="movil-about" src={require("../../assets/images/conocenos/background-blur-movil.png")}></img>
                    <div className='content-counter b-primary'>
                        <div className='count '>
                            <span className='num gotham-bold c-white'>+2000</span>
                            <span className='c-white text'>Viviendas <span className='gotham-bold'>vendidas</span></span>
                        </div>
                        <div className='count b-primary'>
                            <span className='num gotham-bold c-white'>+1200</span>
                            <span className='c-white text'>Familias gozando de su <br></br> <span className='gotham-bold'>nueva vivienda </span></span>
                        </div>
                        <div className='count b-primary'>
                            <span className='num gotham-bold c-white'>+10,000</span>
                            <span className='c-white text'><span className='gotham-bold'>Familias asesoradas</span><br></br> para iniciar su nueva vida </span>
                        </div>
                    </div>
                    <img className="ingeniero" src={require("../../assets/images/conocenos/ingeniero.png")}></img>
                </div>
                <div className='container card-projects '>
                <Slider {...this.state.settingsCards} beforeChange={this.beforeChange}>
                    {
                        this.props.data.cards.map((item)=>{
                            return(
                                <div className='card'>
                                <div className='content-img'>
                                    <img src={item.img}></img>
                                </div>
                                <div className={`content-data b-${item.project}`}>
                                    <div className={`brand brand-${item.project}`}></div>
                                    <div className='link '>{item.text}</div>
                                </div>
                            </div>
                            )
                        })
                    }
                </Slider>
                </div>
                <div className='credit'>
                    <div className='container'>
                        <img className='backgrund-middle-circle' src={require('../../assets/images/conocenos/middle-circle.png')} ></img>
                        <img className='circle-yellow' src={require('../../assets/images/conocenos/circle-yellow.png')} ></img>
                        <img className='circle-orange' src={require('../../assets/images/conocenos/circle-orange.png')} ></img>
                        <img className="img" src={require("../../assets/images/conocenos/pareja.png")}></img>
                        <div className='content-info-credit c-white'>
                            <span className="GothamMedium c-white">Acreditado por</span>
                            <div className='best-place'>
                                <img src={require("../../assets/images/icons/best-place.svg").default}></img>
                            </div>
                            <p className="paragraph c-white">Cosapi Inmobiliaria recibió la acreditación Best Place to Live,
                            considerada la más importante certificación de calidad del sector
                            inmobiliario en América Latina, debido a la satisfacción de sus
                            clientes con sus productos y el servicio entregado.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='all-respaldo'>
                    {/* <div className='mini-ball mini-ball-1'></div> */}
                    <div className='mini-ball mini-ball-2'></div>
                    <div className='container respaldo'>
                        <div className='content-text'>
                            <span className='title c-secondary gotham-bold'>Respaldo</span>
                            <p className='paragraph c-secondary'> <span className='gotham-bold'>Por más de 60 años Cosapi ha contribuido con el desarrollo del Perú,</span> 
                            gracias a la construcción de los principales proyectos de infraestructura del
                            país. Cuenta con experiencia internacional en Chile, Colombia, República
                            Dominicana, Venezuela, entre otros.
                            </p>
                            <div className={`show-more ${this.state.activeParagraphRespaldo ? "active":""}`}>
                                <p className='paragraph c-secondary'>Entre las principales obras ejecutadas o actualmente en desarrollo por Cosapi tenemos:</p>
                                {
                                    this.props.data.features.map((item)=>{
                                        return(
                                            <p className="item-list c-primary">{item}</p>
                                            )
                                        })
                                    }
                            </div>
                            <div className={`leer-mas ${this.state.activeParagraphRespaldo ? "hide": ""}`} onClick={()=>{this.activeParagraphRespaldo()}}>Leer más</div>
                        </div>
                        <div className='content-carousel'>
                            <SliderConocenos slider={this.props.data.slider} events={this.state.events}/>
                        </div>
                    </div>
                </div>
                <section className='politicas-corporativas container d-flex'>
                    <div className='content-img'>
                        <img className='' src={require('../../assets/images/pdfs/family.png')} alt='Políticas coorporativas - Cosapi'></img>
                    </div>
                    <div className='content-pdfs'>
                        <h2 className='title c-secondary gotham-bold'>
                            Políticas corporativas
                        </h2>
                        <p className='paragraph c-secondary mb-1'>Con el objetivo fundamental de reflejar los contenidos de nuestras políticas que integran nuestro Modelo de Cumplimiento y los sistemas que lo conforman, buscamos involucrar nuestra cultura basada en la integridad, transparencia y cumplimiento de la normativa interna y externa. Conoce aquí nuestro modelo de políticas corporativas:</p>
                        <div className='pdfs'>
                            {
                                this.state.pdfs.map((item)=>{
                                    return(
                                        <a className='pdf' href={item.url} target="_blank">
                                            <img className='icon' src={require('../../assets/images/pdfs/icon-doc.svg').default} ></img>
                                            <span className='c-secondary '>{item.title}</span>
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
                <div className='container company'>
                    <img className='model desktop' src={require('../../assets/images/conocenos/woman-model.png')} ></img>
                    <div className='content-company'>
                        <div className='mini-ball'>
                        </div>
                        <div className='mini-ball mini-ball-2'>
                        </div>
                        <div className='content-img-model'>
                        </div>
                        <div className='content-text'>
                            <div className='content-option'>
                                <div className={`option GothamMedium c-white ${this.state.box == 0 ? "active":""}`} onClick={(e)=>{this.selected(0)}}>Visión</div>
                                <div className={`option GothamMedium c-white ${this.state.box == 1 ? "active":""}`} onClick={(e)=>{this.selected(1)}}>Misión</div>
                                <div className={`option GothamMedium c-white ${this.state.box == 2 ? "active":""}`} onClick={(e)=>{this.selected(2)}}>Valores</div>
                            </div>
                            <div className={`box vision ${this.state.box == 0 ? "active" : ""}`}>
                                <p className='paragraph c-white'>Ser en el 2026 la empresa de desarrollo inmobiliario más confiable en el mercado Peruano, ofreciendo la mejor solución de vivienda.</p>
                                    
                            </div>
                            <div className={`box mision ${this.state.box == 1 ? "active" : ""}`}>
                                <p className='paragraph c-white'>Promover productos inmobiliarios de alta calidad destinados a mejorar la calidad de vida de las familias, impactando positivamente en el desarrollo de las ciudades, integrando la vivienda a la industria y al comercio. Ser una empresa sostenible en el tiempo, retribuyendo adecuadamente a nuestros accionistas y fomentando el desarrollo profesional de nuestra gente.</p>
                            </div>
                            <div className={`box valores ${this.state.box == 2 ? "active" : ""}`}>
                                <MediaQuery query="(min-width: 500px)">
                                    <div className='content-icons'>
                                        {
                                            this.props.data.valores.map((item,index)=>{
                                                return(
                                                    <div className={`icon ${this.state.iconValores == index ? "active" : ""}`} onClick={(e)=>{this.iconValores(index)}}>
                                                        <div className={`mask ${item.icon}`}>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <span className='c-white title-paragraph GothamMedium'>{this.props.data.valores[this.state.iconValores].title}</span>
                                    <p className='paragraph c-white'>{
                                        this.props.data.valores[this.state.iconValores].paragraph
                                    }
                                    </p>
                                </MediaQuery>
                                <MediaQuery query="(max-width: 499px)">
                                    {
                                        this.props.data.valores.map((item,index)=>{
                                            return(
                                            <Accordion expanded={this.state.expanded === index} square onChange={this.handleChange(index,this)}>
                                                <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                                                    <Typography>
                                                        <div className={"mask "+item.icon}></div>
                                                        <p className="title c-white">{item.title}</p>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <p className="paragraph c-white">
                                                            {
                                                                item.paragraph
                                                            }
                                                        </p>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            )
                                        })
                                    }
                                </MediaQuery>
                            </div>
                            <img className='model movil' src={require('../../assets/images/conocenos/woman-model.png')} ></img>
                        </div>
                    </div>
                </div>
                <div className='p-relative'>
                    <div className='container responsabilidad'>
                        <img className='background-tree' src={require('../../assets/images/conocenos/tree.png')} ></img>
                        <span className="title c-secondary gotham-bold">Responsabilidad social y ambiental</span>
                        <div className='content-resposabilidad'>
                            <div className='column column-1'>
                                <p className='paragraph c-secondary'>El éxito de Cosapi no depende únicamente de sus resultados, sino
                                también del desarrollo de nuestros grupos de interés. Es por esto
                                que <span className='gotham-bold'>Cosapi mantiene su compromiso, basado en el diálogo
                                continuo como parte de su cultura y estrategia de
                                sostenibilidad.</span> Por segundo año consecutivo, Cosapi S.A. recibió
                                el Distintivo “Empresa Socialmente Responsable”.</p>
                                <div className={`show-more ${this.state.activeParagraphResponsabilidad ? "active":""}`}>
                                    <p className='paragraph c-secondary'>
                                    Además ha publicado el segundo reporte de sostenibilidad con
                                    un nivel B del Global Reporting Initiative (GRI), y ha conseguido la
                                    certificación de la <span className='gotham-bold'>Trinorma para los sistemas de Gestión (ISO
                                    9001, ISO 14001 y OHSAS 18001),</span> la cual también involucra el
                                    compromiso con el cuidado del medio ambiente en todas
                                    nuestras operaciones.
                                    </p>
                                </div>
                            </div>
                            <div className='column column-2'>
                                <div className='show-more'>
                                    <p className='paragraph c-secondary'>
                                    <span className='gotham-bold'> Nuestro objetivo es promover una gestión sostenible,</span> generando bienestar y garantizando la prevención y
                                    permanencia de las fuentes de los recursos naturales.
                                    </p>
                                    <p className='paragraph c-secondary'>
                                        Para nuestros proyectos inmobiliarios desarrollamos un plan
                                        específico de convivencia, aplicable caso a caso, en el cual
                                    <span className='gotham-bold'> definimos las políticas y normas que deben regir posterior a
                                        la entrega de los departamentos.</span> Contamos para este fin con
                                        la asesoría de empresas especializadas.
                                    </p>
                                </div>
                                <div className={`leer-mas ${this.state.activeParagraphResponsabilidad ? "hide": ""}`} onClick={()=>{this.activeParagraphResponsabilidad()}}>Leer más</div>
                                <div className='content-tree'>
                                    <div className='mini-ball mini-ball-2 '></div>
                                    <img className='background-tree movil' src={require('../../assets/images/conocenos/tree.png')} ></img>
                                </div>
                                    <div className='reporte-de-sostenibilidad'>
                                        <select className='select-report' onChange={this.changeSelect}>
                                            <span className='text c-secondary'>Reporte de Sostenibilidad 2018</span>
                                            <div className='content-icon'>
                                                <div className='mask icon-download'></div>
                                            </div>
                                            <option value="reporte_de_sostenibilidad_cosapi_2011">Reporte de sostenibilidad 2011</option>
                                            <option value="reporte_de_sostenibilidad_cosapi_2012">Reporte de sostenibilidad 2012</option>
                                            <option value="reporte_sostenibilidad_cosapi_2013">Reporte de sostenibilidad 2013</option>
                                            <option value="reporte-sostenibilidad-cosapi-2014">Reporte de sostenibilidad 2014</option>
                                            <option value="reporte-sostenibilidad-cosapi-2015">Reporte de sostenibilidad 2015</option>
                                            <option value="reporte-sostenibilidad-cosapi-2016">Reporte de sostenibilidad 2016</option>
                                            <option value="Reporte-Sostenibilidad-2017-COSAPI">Reporte de sostenibilidad 2017</option>
                                            <option value="Reporte-de-Sostenibilidad-2018">Reporte de sostenibilidad 2018</option>
                                            <option value="reporte-de-sostenibilidad-2019-cosapi">Reporte de sostenibilidad 2019</option>
                                            <option value="Reporte_sostenible_2021">Reporte de sostenibilidad 2021</option>
                                            <option value="Reporte_sostenible_2022">Reporte de sostenibilidad 2022</option>
                                        </select>
                                        <a href={`/pdf/${this.state.reportDownload}.pdf`} download className=''>
                                            <img className='btn-download' src={require('../../assets/images/icons/btn-download.svg').default} ></img>
                                        </a>
                                    </div>
                            </div>
                        </div>
                        <div className='certificados'>
                            <div className='content-options'>
                                <MediaQuery query="(min-width: 500px)">
                                    <div className={`option c-secondary ${this.state.certificates == 0 ? "active":""}`} onClick={(e)=>{this.changeCertificates(0)}}>Certificados</div>
                                    <div className={`option c-secondary ${this.state.certificates == 1 ? "active":""}`} onClick={(e)=>{this.changeCertificates(1)}}>Miembros</div>
                                    <div className={`option c-secondary ${this.state.certificates == 2 ? "active":""}`} onClick={(e)=>{this.changeCertificates(2)}}>Licenciatarios</div>
                                    <div className={`option c-secondary ${this.state.certificates == 3 ? "active":""}`} onClick={(e)=>{this.changeCertificates(3)}}>Certificados Cosapi Inmobiliaria</div>
                                </MediaQuery>
                                <MediaQuery query="(max-width: 499px)">
                                    {
                                        <Slider {...this.state.settingsOption}>
                                            <div className={`option c-secondary ${this.state.certificates == 0 ? "active":""}`} onClick={(e)=>{this.changeCertificates(0)}}>Certificados</div>
                                            <div className={`option c-secondary ${this.state.certificates == 1 ? "active":""}`} onClick={(e)=>{this.changeCertificates(1)}}>Miembros</div>
                                            <div className={`option c-secondary ${this.state.certificates == 2 ? "active":""}`} onClick={(e)=>{this.changeCertificates(2)}}>Licenciatarios</div>
                                            <div className={`option c-secondary ${this.state.certificates == 3 ? "active":""}`} onClick={(e)=>{this.changeCertificates(3)}}>Certificados Cosapi Inmobiliaria</div>
                                        </Slider>
                                    }
                                </MediaQuery>

                            </div>
                                <div className={`brands brand-0 ${this.state.certificates == 0 ? "active" : ""}`}>
                                    <div className='content-img'><img className='img' src={require('../../assets/images/conocenos/brand/mivivienda.png')} ></img></div>
                                    <div className='content-img'><img className='img' src={require('../../assets/images/conocenos/brand/esr.png')} ></img></div>
                                    <div className='content-img'><img className='img' src={require('../../assets/images/conocenos/brand/dupont.png')} ></img></div>
                                    <div className='content-img'><img className='img' src={require('../../assets/images/conocenos/brand/abe.png')} ></img></div>
                                    <div className='content-img'>
                                        <div className='circle-hostpod'>
                                            <div className='hostpod hostpod-lms'>
                                                <span className='text'>Certified ISO 37001-2016</span>
                                            </div>
                                        </div>
                                        <img className='img' src={require('../../assets/images/conocenos/brand/lms.png')} ></img>
                                    </div>
                                    <div className='content-img'>
                                        <div className='circle-hostpod'>
                                            <div className='hostpod'>
                                                <span className='text'>Enviromental assurance ISO 14001</span>
                                                <span className='text'>Health and safety assurance ISO18001</span>
                                                <span className='text last-text'>Quality assurance ISO 9001</span>
                                            </div>
                                        </div>
                                        <img className='img' src={require('../../assets/images/conocenos/brand/ms-cert.png')} ></img>
                                    </div>
                                </div>
                                <div className={`brands brand-1 ${this.state.certificates == 1 ? "active" : ""}`}>
                                    <div className='content-img'><img className='img' src={require('../../assets/images/conocenos/brand/adi-peru.png')} ></img></div>
                                    <div className='content-img'><img className='img asei' src={require('../../assets/images/conocenos/brand/asei.svg').default} ></img></div>
                                </div>
                                <div className={`brands brand-2 ${this.state.certificates == 2 ? "active" : ""}`}>
                                    <div className='content-img'><img className='img' src={require('../../assets/images/conocenos/brand/brand-peru.png')} ></img></div>
                                </div>
                                <div className={`brands brand-3 ${this.state.certificates == 3 ? "active" : ""}`}>
                                    <div className='content-img'><img className='img' src={require('../../assets/images/conocenos/brand/best-place.png')} ></img></div>
                                    <div className='content-img'><img className='img' src={require('../../assets/images/conocenos/brand/miviviendaverde.png')} ></img></div>
                                </div>
                        </div>
                                    
                    </div>
                </div>
            </main>
        )
    }
}