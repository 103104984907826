import React, { Component } from 'react';
import './conveniosybeneficios.scss'
import { FormContainer, setInputProps ,checkableBoolProps} from '../../component/common/forms/Form';
import { WorkerBenefitsSubscribeValidatonSchema } from '../../component/common/forms/constraints/ValidatonSchemas';
import { PROJECTS_CRM, PROJECTS_DATA } from '../../data/projects';
import MediaQuery from 'react-responsive';
import { SperantService } from '../../component/common/Http/SperantService';
import Swal from 'sweetalert2';
import { connect } from 'react-redux'
import { active } from '../../data/modal';
import { LeadService } from '../../component/common/Http/LeadService';

class Conveniosybeneficios extends Component {
    constructor(props){
        super(props)
        this.submitForm = this.submitForm.bind(this)
        this.sperantService = new SperantService()
        this.changeOption = this.changeOption.bind(this)
        this.state = {
            option: 1,
            activeModal: false,
        }
    }
    closeModal(){
        this.setState({activeModal:false})
    }

    onSuccess = seller_phone =>{
        this.setState({activeModal:true, sellerPhone: seller_phone})
    }

    onError = _ =>{
        Swal.fire({
            title: '',
            text: '¡Error! Intentelo más tarde',
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'var(--primary)'
        })
    }

    submitForm(values, { resetForm }) {
        const leadService = new LeadService()
        const project = PROJECTS_DATA[values.project]

        Object.assign(values, {
            input_channel_id: project.input_channels.web,
            source_id: project.source_ids.colaborator
        })

        leadService
            .setProject(project)
            .setLeadData(values)
            .save().then((client)=>{
                window.fbq && window.fbq('track', 'Lead', {project: values.project})
                this.onSuccess(client.last_agent_assigned.phone)
                resetForm()
            })
            .catch(e=>this.onError)
    }
    changeOption(option){
        this.setState({option:option})
    }
    initialValues = {
        fname: "",
        lname: "",
        email: "",
        telephone: "",
        working_area: "",
        project: "",
        terms: false,
    }
    render() {
    return (
        <main className="conveniosybeneficios">
            <div className='container'>
                <h1 className="gotham-bold  c-secondary title">Convenios y beneficios</h1>
                <p className='paragraph paragraph-title c-secondary gotham-book'>En Cosapi Inmobiliaria estamos en la constante búsqueda de ofrecer un producto
                    innovador y que cumpla todas tus espectativas para que puedas vivir en
                    confianza. Descubre los beneficios que tenemos para ti y tu empresa.</p>
                <div className='benefits'>
                    <div className='d-flex'>
                        <div className={`content-img`}>
                            <img className='img' src={require('../../assets/images/convenios/convenios.jpg')} ></img>
                        </div>
                        <div className='benefit-text'>
                            <h2 className="gotham-bold c-secondary">Beneficios para aliados<br></br> estratégicos</h2>
                            <p className='paragraph c-secondary gotham-book'>
                                Tenemos convenios con las principales empresas del
                                país. Consulta con el área de bienestar de tu empresa.
                                Si aún no cuentan con este convenio descárgalo y
                                envíalo a <a className="c-secondary GothamMedium" href="mailto:informes@cosapiinmobiliaria.com.pe">informes@cosapiinmobiliaria.com.pe</a>
                            </p>
                            <a href={require("../../assets/images/convenios/COSAPI-INMOBILIARIA-CONVENIO-WEB.pdf")} download className='btn-download c-secondary'>
                                <img className='' src={require('../../assets/images/icons/btn-download.svg').default} ></img>
                                <span className=''>Descargar convenio</span>
                            </a>
                        </div>
                    </div>
                    <div className='d-flex d-flex-form'>
                        <section className="formulario">
                            <MediaQuery query="(min-width:500px)">
                                <h2 className="title-form gotham-bold c-secondary">Beneficios para<br></br> colaboradores Cosapi</h2>
                                <p className='paragraph c-secondary gotham-book'>
                                    Ser parte de la familia Cosapi viene con grandes beneficios para ti y tus familiares, entérate de estos aquí.
                                </p>
                            </MediaQuery>
                            <div className={`popup ${this.state.activeModal ? "active" : ""}`}>
                                <div className="content-popup">
                                    <div className="btn-close" onClick={()=>{this.closeModal()}}><img src={require("../../assets/images/icons/btn-close.svg").default}></img></div>
                                    <img className="background desktop" src={require("../../assets/images/icons/background-modal.png")}></img>
                                    <img className="background movil" src={require("../../assets/images/icons/background-modal-movil.png")}></img>
                                    <img className="icon-check" src={require("../../assets/images/icons/check_icon.svg").default}></img>
                                    <span className="title">¡Gracias!</span>
                                    <span className="sub-title">Dentro de poco un asesor se pondrá en contacto contigo.</span>
                                    {this.state.sellerPhone && <>
                                    <span className="sub-title bold">También puedes contactarlo aquí</span>
                                    <div className="contact">
                                        <a href={`https://wa.me/51${this.state.sellerPhone}`} target="_blank"><img className="icon icon-whatsapp" src={require("../../assets/images/icons/whatsapp-white.svg").default}></img></a>
                                        <a href={`tel:${this.state.sellerPhone}`} target="_blank"><img className="icon" src={require("../../assets/images/icons/call-white.svg").default}></img></a>
                                    </div>
                                    </>}
                                </div>
                            </div>
                            <FormContainer initialValues={this.initialValues} validationSchema={WorkerBenefitsSubscribeValidatonSchema} onSubmit={this.submitForm}>
                                {form => {const {handleSubmit, isSubmitting} = form;
                                return(
                                <form className="form" onSubmit={handleSubmit}>
                                    <div className="content-inputs">
                                        <input placeholder="Nombre" {...setInputProps("fname", "input", form)}></input>
                                        <input placeholder="Apellidos" {...setInputProps("lname", "input", form)}></input>
                                        <input placeholder="Teléfono de contacto" {...setInputProps("telephone", "input-line", form)}></input>
                                        <input placeholder="Correo electrónico" {...setInputProps("email", "input-line", form)}></input>
                                        
                                        <input placeholder="En que área trabajas" {...setInputProps("working_area", "input-line", form)}></input>
                                        <select name="project" {...setInputProps("project", "input-line", form)}>
                                            <option value="">Selecciona tu proyecto</option>
                                            {Object.keys(PROJECTS_DATA).map((key, i)=>
                                                PROJECTS_DATA[key].allowLeadCreation && <option key={i} value={key}>{PROJECTS_DATA[key].name}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="politicas">
                                        <input id="terms" type="checkbox" {...checkableBoolProps("terms", "", form)}></input>
                                        <label htmlFor="terms" className="label-accept"><div className="check"></div></label>
                                        <span className="terms">Acepto las <a target="_blank" href={require("../../assets/files/Politica-de-privacidad.pdf")}>políticas de privacidad</a></span>
                                    </div>
                                    <button type="submit" className="btn-submit btn-radio c-white b-primary" disabled={isSubmitting}>{isSubmitting ? "Enviando" : "Enviar"}</button>
                                </form>)}}
                            </FormContainer>
                        </section>
                        <div className={`content-img content-img-${this.state.option}`}>
                            <MediaQuery query="(max-width:499px)">
                                <h2 className="title-form gotham-bold c-secondary">Beneficios para<br></br> colaboradores Cosapi</h2>
                                <p className='paragraph c-secondary gotham-book'>
                                    Ser parte de la familia Cosapi viene con grandes beneficios para ti y tus familiares, entérate de estos aquí.
                                </p>
                            </MediaQuery>
                            <img className='img' src={require('../../assets/images/convenios/cosapi.jpg')} ></img>
                            <div className='content-options-cosapi'>
                                <div className='cosapi-options'>
                                    <div className={`cosapi-option ${this.state.option == 1 ? "active" : ""}`} onClick={()=>{this.changeOption(1)}}>
                                        Descuento del 2%
                                    </div>
                                    <div className={`cosapi-option ${this.state.option == 2 ? "active" : ""}`} onClick={()=>{this.changeOption(2)}}>
                                        Refiere y gana
                                    </div>
                                    <div className={`cosapi-option ${this.state.option == 3 ? "active" : ""}`} onClick={()=>{this.changeOption(3)}}>
                                        Lanzamientos
                                    </div>
                                </div>
                                <div className={`paragraph-option ${this.state.option == 1 ? "active" : ""}`}>
                                    <p className='paragraph c-secondary gotham-book'>
                                        El 2% es un beneficio adicional a cualquier otro descuento o promoción. Es aplicable para los Cosapinos, socios directos (con convenio) y sus familiares directos.
                                    </p>
                                </div>
                                <div className={`paragraph-option ${this.state.option == 2 ? "active" : ""}`}>
                                    <p className='paragraph c-secondary gotham-book'>
                                        Refiere a un familiar o amigo externo a la organización y gana 4,000 puntos Cosapi. Es requisito que el referido de información de la persona en contacto que hizo la referencia, asimismo este beneficio se ejecutará a los 45 días posteriores de que el referido haya firmado el contrato de compra venta con la inmobiliaria.
                                    </p>
                                    <a href="https://drive.google.com/file/d/1qgMGHRTYKFc6PfxKwNlN0hmDC_gSO_x_/view" target="_blank" className='btn-submit b-primary btn-radio c-white'>
                                        Ver más
                                    </a>
                                </div>
                                <div className={`paragraph-option ${this.state.option == 3 ? "active" : ""}`}>
                                    <p className='paragraph c-secondary gotham-book'>
                                        Ser los primeros siempre trae beneficios, y los Cosapinos, así como nuestros socios siempre tendrán los beneficios de elegir primero, los cuales incluyen elegir las mejores ubicaciones, contar con las mejores promociones y disfrutar la experiencia de Vivir en Confianza.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
  }
}
const MapStateProps = (state)=>{
    return(
        {
            value:state.modalSlice
        }
    )
}
export default connect(MapStateProps,{active})(Conveniosybeneficios)